import { Col, Container, Nav, Row } from "react-bootstrap";

export default function TableOfContents(props) {
  const link = (instance) => {
    const handleClick = (e) => {
      e.preventDefault();
      const headerHeight = 90;
      const element = document.getElementById(instance.id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };

    return (
      <div className="_tx flexed-centered toc-b">
        <Nav.Link href={`#${instance.id}`} onClick={handleClick}>
          <i></i>
          {instance.title}
        </Nav.Link>
      </div>
    );
  };

  const regTable = () => {
    return (
      <Container>
        {props.table &&
          props.table.map((t, tIndex) => (
            <div key={tIndex}>
              {link(t)}
              {t.inner && (
                <>
                  {t.inner.map((i, iIndex) => (
                    <Container key={`${tIndex}-${iIndex}`}>{link(i)}</Container>
                  ))}
                </>
              )}
            </div>
          ))}
      </Container>
    );
  };

  const formulaTable = () => {
    return (
      <Row>
        {props.table &&
          props.table.map((t, tIndex) => (
            <Col key={tIndex}>
              {link(t)}
              {t.inner && (
                <>
                  {t.inner.map((i, iIndex) => (
                    <Container
                      className="formulaTable"
                      key={`${tIndex}-${iIndex}`}
                    >
                      {link(i)}
                    </Container>
                  ))}
                </>
              )}
            </Col>
          ))}
      </Row>
    );
  };

  return (
    <div className="content-table mt-4">
      <span className="fs-5">Tabla de contenidos:</span>
      {props.isFormula ? formulaTable() : regTable()}
    </div>
  );
}
