import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";

//DONE
export default function MLB(props) {
  useEffect(() => {
    let title = "Apuestas en Juegos de Béisbol » Bono $200.000  | Codere®";
    let desc =
      "Aprende apostar con Codere. Aquí te mostramos una guía completa de la MLB, para que juegues como un verdadero profesional del béisbol ⚾.!";

    let can =
      " https://www.codere.bet.ar/cuotas-deportivas/apuestas-beisbol-grandes-ligas";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Cuándo empieza la serie mundial",
          acceptedAnswer: {
            "@type": "Answer",
            text: "La Serie Mundial de Béisbol generalmente comienza a finales de octubre y puede extenderse hasta principios de noviembre, dependiendo de la duración de los playoffs.",
          },
        },
        {
          "@type": "Question",
          name: "¿Qué significa más de 8 en béisbol?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "More than 8:  en el béisbol se refiere a la apuesta de total de carreras, donde se pronostica que habrá más de 8 carreras anotadas en el juego. Si el total real de carreras supera las 8, la apuesta resulta ganadora.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/mlb/${
    props.flag ? "M" : "D"
  }-Baseball-`;
  const jpg = ".jpg";

  const top_par = {
    h1: "Guía completa de apuestas en la MLB: jugá en las grandes ligas con Codere",
    p: "Te mostramos una guía completa de la MLB, para que juegues como un verdadero profesional en la materia. ",
  };

  const table_list = [
    {
      title: "Acerca de La Major League Baseball (MLB)",
      id: "anchor1",
    },
    {
      title: "Serie Mundial de Béisbol",
      id: "anchor2",
    },
    {
      title: "Top de  jugadores",
      id: "anchor3",
    },
    {
      title: "Tipos de apuestas ",
      id: "anchor4",
    },
    {
      title: "Preguntas frecuentes",
      id: "anchor5",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "Acerca de La Major League Baseball ",
    p: [
      "La Major League Baseball (MLB) es la principal liga de béisbol profesional de Estados Unidos y Canadá, y una de las más prestigiosas del mundo. Fundada en 1869, cuenta con 30 equipos divididos en la Liga Americana y la Liga Nacional.",
      "Cada temporada culmina en los playoffs y la Serie Mundial, que define al campeón del año y es seguida por millones de fanáticos. La MLB ha sido hogar de leyendas como Babe Ruth y Jackie Robinson, y es conocida por su rica historia y tradición.",
      "Más que una liga deportiva, la MLB representa pasión, dedicación y excelencia en el béisbol.",
    ],

    subtitles: [
      {
        h3: "Principales Equipos  ",
        p: "Los principales equipos históricos de la Major League Baseball (MLB) incluyen:",
        list: [
          {
            liTitle: "New York Yankees: ",
            liText:
              "Con 27 títulos de Serie Mundial, los Yankees son uno de los equipos más exitosos y reconocibles en la historia del béisbol.",
          },
          {
            liTitle: "Boston Red Sox: ",
            liText:
              'Con una ferviente base de fanáticos y una rivalidad legendaria con los Yankees, los Red Sox han ganado múltiples campeonatos de la Serie Mundial, incluyendo el rompimiento de la "Maldición del Bambino" en 2004.',
          },
          {
            liTitle: "Los Angeles Dodgers: ",
            liText:
              "Con una rica historia y múltiples títulos de Serie Mundial, los Dodgers son uno de los equipos más emblemáticos de la costa oeste de los Estados Unidos.",
          },
          {
            liTitle: "San Francisco Giants: ",
            liText:
              "Conocidos por su longevidad y éxito, los Giants han ganado varios campeonatos de la Serie Mundial, incluyendo recientes victorias en 2010, 2012 y 2014.",
          },
          {
            liTitle: "Chicago Cubs: ",
            liText:
              "A pesar de años de sequía, los Cubs son un equipo icónico con una base de fanáticos leales. Su victoria en la Serie Mundial de 2016 fue un hito histórico.",
          },
          {
            liTitle: "St. Louis Cardinals: ",
            liText:
              "Con múltiples títulos de Serie Mundial y una tradición de éxito, los Cardinals son uno de los equipos más respetados en la MLB.",
          },
        ],
      },
    ],
  };
  const tips = {
    id: "anchor2",
    h2: "Serie Mundial de Béisbol",
    p: "La Serie Mundial de Béisbol es el campeonato anual de la Major League Baseball (MLB) de Estados Unidos y Canadá. En ella, los campeones de la Liga Americana y de la Liga Nacional se enfrentan en una serie al mejor de siete juegos para determinar al campeón del mundo. La Serie Mundial es uno de los eventos deportivos más importantes y seguidos en Estados Unidos, y ha generado momentos icónicos y memorables a lo largo de su historia.",
  };

  const como_hacer = {
    id: "anchor3",
    h2: "Top de jugadores ",
    subtitles: [
      {
        h3: "Mike Trout :  ",
        p: "Considerado por muchos como el mejor jugador en la MLB en la actualidad, Trout es un fuera de serie tanto en el bateo como en la defensa. ",
      },
      {
        h3: "Mookie Betts: ",
        p: "Ganador de múltiples premios MVP y con habilidades excepcionales tanto en el bateo como en el fildeo, Betts es una estrella en ascenso en la liga.",
      },
      {
        h3: "Jacob deGrom: ",
        p: "Uno de los lanzadores más dominantes en la actualidad, deGrom ha ganado múltiples premios Cy Young y es temido por su velocidad y precisión en el montículo.",
      },
      {
        h3: "Fernando Tatis Jr.:  ",
        p: "Una de las jóvenes estrellas en ascenso en la MLB, Tatis Jr. es conocido por su habilidad en el bateo y su espectacular juego defensivo en el campo corto.",
      },
      {
        h3: "Shohei Ohtani:  ",
        p: 'Conocido como "El fenómeno", Ohtani es único en la MLB por su habilidad para lanzar y batear a un alto nivel. Es una sensación tanto en el montículo como en el plato.',
      },
      {
        h3: "Juan Soto:  ",
        p: "Con habilidades excepcionales en el bateo y una visión impresionante en el plato, Soto es considerado uno de los mejores bateadores jóvenes en la liga.",
      },
    ],

    p: "Estos son solo algunos ejemplos de los muchos jugadores talentosos que actualmente compiten en la MLB y que continúan deleitando a los fanáticos con su habilidad y excelencia en el juego.",
  };

  const apuesta = {
    id: "anchor4",
    h2: "¿Cómo se apuesta en el béisbol? ",
    p: "Apostar en el béisbol es emocionante y ofrece una variedad de opciones para los aficionados. Aquí hay algunas formas comunes de apostar en este deporte:",
    inner: [
      {
        h3: "Total de carreras Más/Menos ",
        p: "En esta apuesta, los apostadores deben predecir si el total de carreras anotadas en un juego será más o menos que un número específico establecido por el sportsbook.",
      },
      {
        h3: "Ganador del partido ",
        p: "Esta apuesta implica seleccionar al equipo que se espera que gane el juego. Las cuotas varían según la fortaleza de cada equipo y las condiciones del juego.",
      },
      {
        h3: "Handicap (Sin Empate) ",
        p: "En esta apuesta, se asigna un handicap a uno de los equipos para nivelar el campo de juego. Los apostadores pueden apostar por el equipo favorito para superar el handicap o por el equipo menos favorito para no perder por más de cierta cantidad de carreras, dependiendo de las reglas del handicap. En este tipo de apuesta, no hay opción de empate.",
      },
    ],
  };

  const preguntas = {
    id: "anchor5",
    h2: "Preguntas frecuentes ",
    inner: [
      {
        h3: "¿Cuándo empieza la serie mundial?   ",
        p: "La Serie Mundial de Béisbol generalmente comienza a finales de octubre y puede extenderse hasta principios de noviembre, dependiendo de la duración de los playoffs.",
      },
      {
        h3: "¿Qué significa más de 8 en béisbol? ",
        p: "More than 8:  en el béisbol se refiere a la apuesta de total de carreras, donde se pronostica que habrá más de 8 carreras anotadas en el juego. Si el total real de carreras supera las 8, la apuesta resulta ganadora.",
      },
    ],
  };

  return (
    <>
      <Floating text="Apuestas de MLB" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/mlb/${
            props.flag ? "M" : "D"
          }-Header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event">
          <Container>
            <h1 className="header subtitle">{top_par.h1}</h1>
            <p>{top_par.p}</p>
            {!props.flag ? <TableOfContents table={table_list} /> : <></>}

            <div id={history.id}>
              <h2 className="subtitle">{history.h2}</h2>

              {history.p.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}

              {history.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.p}</p>
                  {inn.list && (
                    <ol>
                      {inn.list.map((li, k) => (
                        <li key={k} className={"mb-2"}>
                          <strong>{li.liTitle}</strong> {li.liText}
                        </li>
                      ))}
                    </ol>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-4" id={tips.id}>
              <h2 className="subtitle">{tips.h2}</h2>
              <p>{tips.p}</p>
            </div>

            <div className="mt-5" id={como_hacer.id}>
              <h2 className="subtitle">{como_hacer.h2}</h2>
              {como_hacer.subtitles && (
                <ul>
                  {como_hacer.subtitles.map((inn, k) => (
                    <li key={k} className={"mb-2"}>
                      <strong>{inn.h3}</strong> {inn.p}
                    </li>
                  ))}
                </ul>
              )}

              <p>{como_hacer.p}</p>
            </div>

            <div className="mt-5" id={apuesta.id}>
              <h2 className="subtitle">{apuesta.h2}</h2>
              <p>{apuesta.p}</p>
              {apuesta.inner && (
                <div>
                  {apuesta.inner.map((inn, k) => (
                    <div key={k} className={"mb-2"}>
                      <h3 className="subtitle">{inn.h3}</h3>
                      <p>{inn.p}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>{inn.p}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + "1" + jpg} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
