import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";

export default function Ciclismo(props) {
  useEffect(() => {
    let title = "Conce las Apuestas en Ciclismo  » Bono $200.000  | Codere®";
    let desc =
      "Aquí te dejamos las opciones que tenés que saber antes de realizar tu apuesta. Si te interesa difreutar del ciclismo 🚴🏻 en otro nivel, estás en el lugar correcto.";

    let can = "https://www.codere.bet.ar/cuotas-deportivas/apuestas-ciclismo";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Cuándo es el mundial de ciclismo?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "El Campeonato Mundial de Ciclismo en Ruta se lleva a cabo anualmente y generalmente tiene lugar en el mes de septiembre. Sin embargo, la fecha exacta puede variar dependiendo del calendario y de la ubicación del evento en un año determinado. El Campeonato Mundial de Ciclismo en Ruta incluye diferentes disciplinas, como la carrera en ruta, contrarreloj individual, contrarreloj por equipos y carreras para ciclistas sub-23 y juniors. Es uno de los eventos más importantes en el calendario del ciclismo mundial y atrae a los mejores ciclistas de todo el mundo.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuál es el mejor circuito?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Tour de Francia: La joya de la corona del ciclismo. El Tour de Francia, conocido por ser la carrera por etapas más dura y prestigiosa del mundo, es un evento que todo ciclista aspira a ganar. Dentro de este tour tenemos a estos dos cicuitos.  Mont Ventoux: Una montaña icónica en el Tour de Francia, conocida por sus pendientes empinadas y desafiantes. Alpe d'Huez: Otro famoso puerto de montaña en el Tour de Francia, conocido por sus 21 curvas y su final en lo alto.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/ciclismo/${
    props.flag ? "M" : "D"
  }-Cycling-`;
  const jpg = ".jpg";

  const top_par = {
    h1: "Apuestas para Ciclismo, el desafío está por comenzar",
    p: "El ciclismo es uno de los deportes más elegidos entre los clientes de Codere. Hoy queremos que seas un experto y juegues con todos los detalles y precisiones necesarias. Te dejamos las opciones que tenés que saber antes de realizar tu apuesta. Si te interesa jugar con el ciclismo, estás en el lugar correcto.",
  };

  const table_list = [
    {
      title: "El ciclismo en el mundo de las apuestas",
      id: "anchor1",
    },
    {
      title: "Consejos para apostar en ciclismo",
      id: "anchor2",
    },
    {
      title: "Preguntas Frecuentes",
      id: "anchor3",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "El ciclismo en el mundo de las apuestas",
    p: "En el mundo de las apuestas deportivas, el ciclismo es una disciplina popular y ofrece una variedad de opciones de apuestas en Codere. Entre las apuestas comunes que podrás encontrar para eventos de ciclismo, se incluyen:",
    tipsDeApuesta: "Tipos de apuesta: ",
    list: [
      {
        liTitle: "Ganador de la carrera: ",
        liText:
          "apostar por el ciclista que cruzará la línea de meta en primer lugar.",
      },
      {
        liTitle: "Podio: ",
        liText:
          "apostar por los ciclistas que terminarán entre los tres primeros lugares en la carrera.",
      },
      {
        liTitle: "Clasificación general: ",
        liText:
          "apostar por el ciclista que terminará en la clasificación general al final de una carrera por etapas o un tour.",
      },
      {
        liTitle: "Etapa ganadora: ",
        liText:
          "apostar por el ciclista que ganará una etapa específica en una carrera por etapas o un tour.",
      },
      {
        liTitle: "Campeón de la clasificación de montaña: ",
        liText:
          "apostar por el ciclista que acumulará la mayor cantidad de puntos en las etapas de montaña.",
      },
      {
        liTitle: "Campeón de la clasificación por puntos: ",
        liText:
          "apostar por el ciclista que acumulará la mayor cantidad de puntos en las etapas planas.",
      },
      {
        liTitle: "Número de escapadas: ",
        liText:
          "apostar por el número de ciclistas que formarán una escapada en una etapa específica.",
      },
      {
        liTitle: "Tiempo de llegada: ",
        liText:
          "apostar por el tiempo que tardará el ganador en completar una etapa o una carrera completa.",
      },
    ],
    enMundo: [
      {
        h3: "Las carreras más importantes en mundo ",
        p: "En el mundo del ciclismo, hay varias carreras consideradas como las más importantes y prestigiosas. Estas son algunas de las carreras más destacadas:",
        data: [
          {
            strong: "Tour de Francia: ",
            text: "probablemente la carrera de ciclismo más famosa y prestigiosa del mundo. Se celebra anualmente en Francia durante tres semanas en julio y atrae a los mejores ciclistas del mundo.",
          },
          {
            strong: "Giro de Italia: ",
            text: "otra de las Grandes Vueltas, se celebra anualmente en Italia durante tres semanas en mayo y junio. Es conocido por su desafiante recorrido montañoso y sus espectaculares paisajes.",
          },
          {
            strong: "Vuelta a España: ",
            text: "la tercera de las Grandes Vueltas, se lleva a cabo en España durante tres semanas en agosto y septiembre. Es conocida por sus etapas montañosas y su clima extremo.",
          },
          {
            strong: "Campeonato Mundial de Ciclismo en Ruta: ",
            text: "se celebra anualmente y reúne a los mejores ciclistas del mundo para competir por el título de campeón mundial en diferentes disciplinas, incluyendo la carrera en ruta, contrarreloj individual y contrarreloj por equipos.",
          },
          {
            strong: "Monumentos del Ciclismo: ",
            text: "estas son cinco de las carreras de un día más antiguas y prestigiosas del mundo: Milán-San Remo, Tour de Flandes, París-Roubaix, Lieja-Bastoña-Lieja y Giro de Lombardía. Cada una tiene su propia historia y desafíos únicos.",
          },
        ],
      },
    ],
    principalesEquipos: [
      {
        h3: "Principales equipos ",
        p: "En el mundo del ciclismo, hay varios equipos que se destacan por su historia, éxito y prestigio. Algunos de los principales equipos en la actualidad incluyen:",
        data: [
          {
            strong: "INEOS Grenadiers: ",
            text: "anteriormente conocido como Team Sky, es uno de los equipos más exitosos en la última década, con múltiples victorias en el Tour de Francia y otras Grandes Vueltas.",
          },
          {
            strong: "Deceuninck-Quick-Step: ",
            text: "este equipo belga es conocido por su éxito en las clásicas de primavera y las etapas de sprints, con varios especialistas en estas disciplinas.",
          },
          {
            strong: "Team Jumbo-Visma: ",
            text: "un equipo holandés que ha experimentado un ascenso notable en los últimos años, compitiendo en las Grandes Vueltas y clásicas de un día con un fuerte enfoque en el contrarreloj y las etapas de montaña.",
          },
          {
            strong: "UAE Team Emirates: ",
            text: "uno de los equipos más destacados en el pelotón actual, con ciclistas de renombre mundial como Tadej Pogacar y Alexander Kristoff.",
          },
          {
            strong: "BORA-hansgrohe:",
            text: "un equipo alemán que se ha destacado en las Grandes Vueltas y las clásicas de un día, con énfasis en el rendimiento en las etapas de montaña y en los sprints.",
          },
          {
            strong: "AG2R Citroën Team: ",
            text: "equipo francés con una larga historia en el ciclismo profesional, con un enfoque en las clásicas y las Grandes Vueltas.",
          },
          {
            strong: "Movistar Team: ",
            text: "uno de los equipos más reconocidos en el pelotón, con éxitos en las Grandes Vueltas y en las carreras por etapas.",
          },
        ],
      },
    ],
    ciclistasEnElMundo: [
      {
        h3: "Top de ciclistas en el mundo ",
        p: 'Determinar un "top" de ciclistas en el mundo puede ser subjetivo y puede variar según los criterios utilizados, como el tipo de terreno, las competiciones, la especialidad del ciclista, entre otros factores. Te presentamos una lista de algunos de los ciclistas más destacados en el mundo del ciclismo en la actualidad:',
        list: [
          //list should be numbered
          {
            liTitle: "Tadej Pogacar (Eslovenia): ",
            liText:
              "Ganador del Tour de Francia en 2020 y 2021, y considerado como una de las mayores promesas del ciclismo.",
          },
          {
            liTitle: "Primoz Roglic (Eslovenia): ",
            liText:
              "Un ciclista polivalente con victorias en el Tour de España y en otras importantes competiciones por etapas.",
          },
          {
            liTitle: "Mathieu van der Poel (Países Bajos): ",
            liText:
              "Conocido por su versatilidad y éxito en ciclocross, MTB y carretera, con victorias en clásicas como la Amstel Gold Race.",
          },
          {
            liTitle: "Wout van Aert (Bélgica): ",
            liText:
              "Otro ciclista polivalente que ha brillado en el ciclocross, las clásicas y las etapas de montaña en el Tour de Francia.",
          },
          {
            liTitle: "Julian Alaphilippe (Francia): ",
            liText:
              "Especialista en carreras de un día y escalador habilidoso, ha ganado etapas en el Tour de Francia y ha sido campeón del mundo en ruta.",
          },
          {
            liTitle: "Egan Bernal (Colombia): ",
            liText:
              "Ganador del Tour de Francia en 2019, es un escalador talentoso y uno de los líderes del ciclismo colombiano.",
          },
          {
            liTitle: "Richard Carapaz (Ecuador): ",
            liText:
              "Ganador del Giro de Italia en 2019 y medallista olímpico, es uno de los principales escaladores del pelotón.",
          },
          {
            liTitle: "Alejandro Valverde (España): ",
            liText:
              "Un veterano del pelotón con numerosas victorias en carreras por etapas y clásicas, así como medallista de campeonatos del mundo.",
          },
          {
            liTitle: "Peter Sagan (Eslovaquia): ",
            liText:
              'Conocido como "el campeón del mundo", es un especialista en esprint y clásicas, con múltiples victorias en el Tour de Francia y otros grandes eventos.',
          },
          {
            liTitle: "Michael Woods (Canadá): ",
            liText:
              "Un escalador fuerte que ha destacado en carreras por etapas y ha logrado buenos resultados en las clásicas.",
          },
        ],
      },
    ],
    principalesCiclistasArgentinos: [
      {
        h3: "Principales ciclistas  Argentinos ",
        p: "Argentina tiene una gran tradición en el ciclismo, con varios ciclistas destacados en diferentes disciplinas. Aquí hay algunos de los principales ciclistas argentinos:",
        list: [
          //list should be numbered
          {
            liTitle: "Eduardo Sepúlveda: ",
            liText:
              'Conocido como "El Gato", es uno de los ciclistas argentinos más destacados en la actualidad. Ha competido en equipos WorldTour y ha participado en grandes vueltas como el Tour de Francia y el Giro de Italia.',
          },
          {
            liTitle: "Maximiliano Richeze: ",
            liText:
              "Especialista en esprint, ha tenido una exitosa carrera en el pelotón internacional, compitiendo para equipos WorldTour y ganando etapas en importantes carreras como la Vuelta a España.",
          },
          {
            liTitle: "Daniel Díaz: ",
            liText:
              'Conocido como "El Puma", ha sido uno de los escaladores argentinos más destacados en los últimos años, compitiendo en equipos profesionales y obteniendo victorias en carreras por etapas en América del Sur.',
          },
          {
            liTitle: "Matías Medici: ",
            liText:
              "Otro escalador argentino talentoso, ha competido en equipos continentales y ha tenido éxito en carreras de montaña en América del Sur.",
          },
          {
            liTitle: "Juan José Haedo: ",
            liText:
              "Retirado en 2017, Haedo fue un destacado esprínter argentino, compitiendo en equipos WorldTour y obteniendo múltiples victorias en etapas de carreras importantes como la Vuelta a España y el Tour de California.",
          },
          {
            liTitle: "Walter Pérez: ",
            liText:
              "Especialista en pista, ha representado a Argentina en múltiples Juegos Olímpicos y ha ganado medallas de oro en eventos como el Campeonato Mundial de Pista y los Juegos Panamericanos.",
          },
        ],
      },
    ],
  };

  const tips = {
    id: "anchor2",
    h2: "Consejos para apostar en ciclismo  ",
    p: "Apostar en ciclismo puede ser emocionante y gratificante, pero también requiere un buen entendimiento del deporte y de las estrategias de apuestas. Acá tenés algunos consejos para apostar en ciclismo:",
    subtitles: [
      {
        h3: "Conocé el deporte: ",
        p: "Antes de apostar, asegurate de entender cómo funcionan las diferentes competiciones, los tipos de etapas y las estrategias de carrera. Esto te va a ayudar a tomar decisiones más informadas al hacer tus apuestas.",
      },
      {
        h3: "Analizá el recorrido: ",
        p: "Cada carrera tiene un recorrido único, con diferentes tipos de terreno, altimetrías y condiciones climáticas. Analizá el perfil de la carrera y cómo puede afectar a los diferentes tipos de ciclistas.",
      },
      {
        h3: "Tené en cuenta el estado de forma: ",
        p: "Evaluá el estado de forma de los ciclistas participantes. Considerá su historial de resultados recientes, lesiones y cualquier otro factor que pueda influir en su rendimiento.",
      },
      {
        h3: "Observá las tácticas del equipo: ",
        p: "En el ciclismo, el trabajo en equipo es crucial. Observá cómo trabajan juntos los equipos y cómo distribuyen sus esfuerzos para apoyar a su líder o buscar victorias de etapa.",
      },
      {
        h3: "Seguí las noticias y la actualidad: ",
        p: "Mantenete al tanto de las noticias y actualizaciones sobre el mundo del ciclismo. Esto puede incluir lesiones, cambios de equipo, condiciones climáticas y otras noticias relevantes que puedan influir en el resultado de una carrera.",
      },
      {
        h3: "Considerá las apuestas en vivo: ",
        p: "Las apuestas en vivo pueden ser una forma emocionante de apostar en ciclismo, ya que te permiten ajustar tus apuestas según lo que esté sucediendo en la carrera. Sin embargo, asegurate de estar atento y tener un buen entendimiento de la carrera para tomar decisiones acertadas.",
      },
      {
        h3: "Gestioná tu bankroll: ",
        p:
          "Como con cualquier forma de apuestas, es importante gestionar responsablemente tu bankroll y no apostar más de lo que puedas permitirte perder. Establecé un presupuesto y apostá de manera consciente.\n" +
          "\n" +
          "Recordá que el ciclismo es un deporte impredecible y que siempre existe un factor de riesgo en las apuestas. No hay garantías de éxito, pero con investigación y análisis cuidadosos, podés aumentar tus posibilidades de tomar decisiones acertadas al hacer apuestas en ciclismo.\n",
      },
    ],
  };

  const preguntas = {
    id: "anchor3",
    h2: "Preguntas frecuentes ",
    inner: [
      {
        h3: "¿Cuándo es el mundial de ciclismo?  ",
        p: "El Campeonato Mundial de Ciclismo en Ruta se lleva a cabo anualmente y generalmente tiene lugar en el mes de septiembre. Sin embargo, la fecha exacta puede variar dependiendo del calendario y de la ubicación del evento en un año determinado. El Campeonato Mundial de Ciclismo en Ruta incluye diferentes disciplinas, como la carrera en ruta, contrarreloj individual, contrarreloj por equipos y carreras para ciclistas sub-23 y juniors. Es uno de los eventos más importantes en el calendario del ciclismo mundial y atrae a los mejores ciclistas de todo el mundo.",
      },
      {
        h3: "¿Cuál es el mejor circuito?  ",
        data: [
          {
            strong: "Tour de Francia: ",
            Text: "La joya de la corona del ciclismo. El Tour de Francia, conocido por ser la carrera por etapas más dura y prestigiosa del mundo, es un evento que todo ciclista aspira a ganar. Dentro de este tour tenemos a estos dos cicuitos. ",
          },
          {
            strong: "Mont Ventoux: ",
            Text: "Una montaña icónica en el Tour de Francia, conocida por sus pendientes empinadas y desafiantes.",
          },
          {
            strong: "Alpe d'Huez: ",
            Text: "Otro famoso puerto de montaña en el Tour de Francia, conocido por sus 21 curvas y su final en lo alto.",
          },
        ],
      },
    ],
  };

  return (
    <>
      <Floating text="Apuestas de Ciclismo" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/ciclismo/${
            props.flag ? "M" : "D"
          }-Header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event">
          <Container>
            <h1 className="header subtitle">{top_par.h1}</h1>
            <p>{top_par.p}</p>
            {!props.flag ? <TableOfContents table={table_list} /> : <></>}

            <div id={history.id}>
              <h2 className="subtitle">{history.h2}</h2>
              <p>{history.p}</p>

              <h3 className="subtitle">{history.tipsDeApuesta}</h3>

              {history.list.map((item, index) => (
                <p key={index}>
                  <strong>{item.liTitle}</strong>
                  {item.liText}
                </p>
              ))}

              {history.enMundo.map((section, index) => (
                <div key={index}>
                  <h3 className="subtitle">{section.h3}</h3>
                  <p>{section.p}</p>

                  {section.data.map((item, idx) => (
                    <p key={idx}>
                      <strong>{item.strong}</strong>
                      {item.text}
                    </p>
                  ))}
                </div>
              ))}

              {history.principalesEquipos.map((section, index) => (
                <div key={index}>
                  <h3 className="subtitle">{section.h3}</h3>
                  <p>{section.p}</p>

                  {section.data.map((item, idx) => (
                    <p key={idx}>
                      <strong>{item.strong}</strong>
                      {item.text}
                    </p>
                  ))}
                </div>
              ))}

              {history.ciclistasEnElMundo.map((section, index) => (
                <div key={index}>
                  <h3 className="subtitle">{section.h3}</h3>
                  <p>{section.p}</p>
                  <ol>
                    {section.list.map((item, idx) => (
                      <li key={idx} className={"mb-2"}>
                        <strong>{item.liTitle}</strong>
                        {item.liText}
                      </li>
                    ))}
                  </ol>
                </div>
              ))}

              {history.principalesCiclistasArgentinos.map((section, index) => (
                <div key={index}>
                  <h3 className="subtitle">{section.h3}</h3>
                  <p>{section.p}</p>
                  <ol>
                    {section.list.map((item, idx) => (
                      <li key={idx} className={"mb-2"}>
                        <strong>{item.liTitle}</strong>
                        {item.liText}
                      </li>
                    ))}
                  </ol>
                </div>
              ))}
            </div>

            <div className="mt-4" id={tips.id}>
              <h2 className="subtitle">{tips.h2}</h2>
              <p>{tips.p}</p>
              {tips.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.p}</p>
                </div>
              ))}
            </div>

            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>
                      {inn.p ? (
                        <p>{inn.p}</p>
                      ) : (
                        <ul>
                          {inn.data.map((item, index) => (
                            <li key={index}>
                              <strong>{item.strong}</strong>
                              {item.Text}
                            </li>
                          ))}
                        </ul>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>

            <LazyLoadImage src={prefix + "1" + jpg} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
