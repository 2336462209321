import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SPEEDSIZE_PREFIX } from "../../App";
import React from "react";

export default function Navigations({ type, index }) {
  const prefixMap = [
    `eventos-deportivos`,
    `casino/assets`,
    `cuotas-deportivas`,
  ];

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/${prefixMap[type]}/icons/`;

  const navs = [
    [
      {
        icon: "baloncesto",
        title: "Baloncesto",
        url: `apuestas-mundial-baloncesto`,
      },
      {
        icon: "formula1",
        title: "Grand Prix",
        url: "apuestas-grand-prix",
      },
      {
        icon: "mundial",
        title: "Mundial",
        url: "apuestas-mundial-fifa",
      },
      {
        icon: "mlb",
        title: "Baseball",
        url: "apuestas-serie-mundial",
      },
      {
        icon: "superbowl",
        title: "Superbowl",
        url: "apuestas-superbowl",
      },
    ],
    [
      {
        icon: "casino",
        title: "Casino",
        url: "", // already concated by default !!
      },
      {
        icon: "en-vivo",
        title: "En Vivo",
        url: "casino-en-vivo",
      },
      {
        icon: "slots",
        title: "Slots",
        url: "slots",
      },
      {
        icon: "blackjack",
        title: "Blackjack",
        url: "blackjack",
      },
      {
        icon: "Baccarat",
        title: "Baccarat",
        url: "baccarat",
      },
      {
        icon: "ruleta",
        title: "Ruleta",
        url: "ruleta",
      },
    ],
    [
      {
        icon: "baloncesto",
        title: "Baloncesto",
        url: "apuestas-baloncesto",
      },
      {
        icon: "formula1",
        title: "F1",
        url: "apuestas-formula1",
      },
      {
        icon: "soccer",
        title: "Fútbol",
        url: "apuestas-futbol",
      },
      {
        icon: "cycling",
        title: "Ciclismo",
        url: "apuestas-ciclismo",
      },
      {
        icon: "superbowl",
        title: "NFL",
        url: "apuestas-nfl-futbol-americano",
      },
      {
        icon: "ufc",
        title: "UFC",
        url: "apuestas-ufc",
      },
      {
        icon: "tenis",
        title: "Tenis",
        url: "apuestas-tenis",
      },
      {
        icon: "mlb",
        title: "MLB",
        url: "apuestas-beisbol-grandes-ligas",
      },
    ],
  ];

  return (
    <div className="e-nav d-flex align-items-center">
      {navs[type].map((n, k) => (
        <React.Fragment key={k}>
          <div
            className={`seo-nav-tab ${
              index === k + 1 ? `${type === 1 ? "c-" : ""}seo-nav-active` : ""
            }`}
          >
            <Link
              to={`/${prefixMap[type].split("/")[0]}${
                n.url ? `/${n.url}` : ""
              }`}
              className={`text-white text-decoration-none ${
                index !== k + 1 ? "hoverable" : ""
              }`}
            >
              <LazyLoadImage
                src={prefix + n.icon + ".svg"}
                width={28}
                height={28}
              />
              <span className="seo-nav-title">{n.title}</span>
              <span className="underline" />
            </Link>
          </div>
          {k < navs[type].length - 1 && <div className="c-vr"></div>}
        </React.Fragment>
      ))}
    </div>
  );
}
