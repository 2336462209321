import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { rediretModal } from "../Hooks/MySignals";

const CabaMendozaPopup = () => {
  // TODO   const location = useLocation();

  // const [isPopupVisible, setPopupVisible] = useState(showRedirectModal(false));
  const [additionalText, setAdditionalText] = useState("");
  const [intendedPath, setIntendedPath] = useState("");
  const [cabaUrl, setCabaUrl] = useState(
    "https://m.caba.codere.bet.ar/Deportes/#/HomePage"
  );
  const [mendozaUrl, setMendozaUrl] = useState(
    "https://m.mdz.codere.bet.ar/deportesmendoza/#/HomePage"
  );
  const [activeElement, setActiveElement] = useState(null);
  const [originalUrl, setOriginalUrl] = useState("");

  const urls = {
    register: {
      caba: "https://m.caba.codere.bet.ar/Deportes/#/mso/RegistroARPage",
      mendoza:
        "https://m.mdz.codere.bet.ar/deportesmendoza/#/mso/RegistroMZAPage",
    },
    login: {
      caba: "https://m.caba.codere.bet.ar/Deportes/#/HomePage?openlogin=true",
      mendoza:
        "https://m.mdz.codere.bet.ar/deportesmendoza/#/HomePage?openlogin=true",
    },
    casino: {
      caba: "https://m.caba.codere.bet.ar/Deportes/#/casino/CasinoPage?lobby=ArgentinaCaba",
      mendoza:
        "https://m.mdz.codere.bet.ar/deportesmendoza/#/casino/CasinoPage?lobby=ArgentinaMendoza",
    },
    casinoLive: {
      caba: "https://m.caba.codere.bet.ar/Deportes/#/casino/CasinoEnVivoPage?lobby=EnVivoAR",
      mendoza:
        "https://m.mdz.codere.bet.ar/deportesmendoza/#/casino/CasinoPage?lobby=ArgentinaMendoza",
    },
    sport: {
      caba: "https://m.caba.codere.bet.ar/Deportes/#/HomePage",
      mendoza: "https://m.mdz.codere.bet.ar/deportesmendoza/#/HomePage",
    },
    sportLive: {
      caba: "https://m.caba.codere.bet.ar/Deportes/#/DirectosPage",
      mendoza: "https://m.mdz.codere.bet.ar/deportesmendoza/#/DirectosPage",
    },
    promotions: {
      caba: "https://m.caba.codere.bet.ar/Deportes/#/mso/PromotionsPage",
      mendoza: "https://m.mdz.codere.bet.ar/deportesmendoza/#/HomePage",
    },
    ourRooms: {
      caba: "https://bingos.codere.bet.ar/",
      mendoza: "https://bingos.codere.bet.ar/",
    },
    tyc: {
      caba: "https://www.codere.bet.ar/ayuda",
      mendoza:
        "https://m.mdz.codere.bet.ar/deportesmendoza/#/mso/HelpPage?animate=false",
    },
  };

  const determineUrls = (path, originalUrl, className) => {
    let cabaUrl = "";
    let mendozaUrl = "";

    if (className.includes("codereTrs54rsTYC")) {
      cabaUrl = urls.tyc.caba;
      mendozaUrl = urls.tyc.mendoza;
    } else if (path.includes("ArgentinaCaba&playgame")) {
      cabaUrl = originalUrl;
      mendozaUrl = urls.casino.mendoza;
    } else if (
      path.includes("RegistroARPage") ||
      path.includes("RegistroMZAPage")
    ) {
      cabaUrl = urls.register.caba;
      mendozaUrl = urls.register.mendoza;
    } else if (path.includes("openlogin=true")) {
      cabaUrl = urls.login.caba;
      mendozaUrl = urls.login.mendoza;
    } else if (
      path.includes("CasinoPage?lobby=ArgentinaCaba") ||
      path.includes("CasinoPage?lobby=ArgentinaMendoza")
    ) {
      cabaUrl = urls.casino.caba;
      mendozaUrl = urls.casino.mendoza;
    } else if (path.includes("CasinoEnVivoPage?lobby=EnVivoAR")) {
      cabaUrl = urls.casinoLive.caba;
      mendozaUrl = urls.casinoLive.mendoza;
    } else if (path.includes("DirectosPage")) {
      cabaUrl = urls.sportLive.caba;
      mendozaUrl = urls.sportLive.mendoza;
    } else if (path.includes("mso/PromotionsPage")) {
      cabaUrl = urls.promotions.caba;
      mendozaUrl = urls.promotions.mendoza;
    } else if (originalUrl.includes("bingos")) {
      cabaUrl = urls.ourRooms.caba;
      mendozaUrl = urls.ourRooms.mendoza;
    } else {
      cabaUrl = urls.sport.caba;
      mendozaUrl = urls.sport.mendoza;
    }

    setCabaUrl(cabaUrl);
    setMendozaUrl(mendozaUrl);
  };

  useEffect(() => {
    const handleClick = (event) => {
      const target = event.target.closest("a");
      if (!target) return;

      const excludedClasses = [
        "cookie-btn",
        "codereTrs54rs",
        "social-media",
        "close-button",
        "button-link",
        "accordion-button",
        "dropdown-toggle",
        "dropdown-item",
        "e-nav",
        "card-header",
        "ev-button",
        "eventos_card",
        "toc-b",
        "footerLogoRTA",
        "accordion-body",
        "footerlogosRTA-area",
      ];

      const excludedSelectors = [
        ".social-media a",
        ".social-media li",
        ".social-media p",
        ".e-nav a",
      ];

      const isExcluded =
        excludedClasses.some((className) => target.closest(`.${className}`)) ||
        excludedSelectors.some((selector) => target.closest(selector));

      if (
        !isExcluded &&
        (target.closest("nav") ||
          target.tagName === "BUTTON" ||
          target.className.includes("codereGameImg") ||
          target.className.includes("img-fluid") ||
          target.className.includes("hvr") ||
          target.tagName === "A" ||
          target.tagName === "IMG" ||
          target.className.includes("topGameImage") ||
          target.className.includes("nav-link") ||
          target.className.includes("NavLink"))
      ) {
        event.preventDefault();
        rediretModal.value = true;
        // setPopupVisible(showRedirectModal(true));

        const href = target.href;
        setOriginalUrl(href);
        let path = href.split(".ar")[1];

        if (
          target.className.includes("registrate-button") ||
          target.className.includes("central-regis")
        ) {
          setAdditionalText("Registrate según el domicilio en tu DNI");
        } else if (target.className.includes("acceder-button")) {
          setAdditionalText("Acceder según el domicilio en tu DNI");
        } else {
          setAdditionalText("");
        }

        setIntendedPath(path);
        determineUrls(path, href, target.className);

        if (target.classList.contains("active")) {
          setActiveElement(target);
        }
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleChoice = (event) => {
    event.preventDefault();
    rediretModal.value = false;
    // setPopupVisible(showRedirectModal(false));

    setAdditionalText("");

    if (activeElement) {
      activeElement.classList.remove("active");
      setActiveElement(null);
    }

    if (!intendedPath) return;

    const fullUrl = event.target.href;

    window.location.href = fullUrl;
  };

  const handleClose = () => {
    // setPopupVisible(showRedirectModal(false));
    rediretModal.value = false;
    setAdditionalText("");

    if (activeElement) {
      activeElement.classList.remove("active");
      setActiveElement(null);
    }
  };

  useEffect(() => {
    console.log("a");
  }, [rediretModal.value]);

  return (
    <>
      {rediretModal.value && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="titleAreaPopup">
              <h2 className="coderePopupH2">¿Desde dónde querés jugar?</h2>
              {additionalText && (
                <p className={"codereAdditionalText"}>{additionalText}</p>
              )}
            </div>

            <div className="popUpBtn">
              <a onClick={handleChoice} className="button-link" href={cabaUrl}>
                Caba
              </a>
              <a
                onClick={handleChoice}
                className="button-link"
                href={mendozaUrl}
              >
                Mendoza
              </a>
            </div>
            <button className="close-button" onClick={handleClose}></button>
            <div className={"popupFooter"}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default CabaMendozaPopup;
