import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";

export default function Baloncesto(props) {
  useEffect(() => {
    let title = "Aprende Apostar en Baloncesto » Bono $200.000  | Codere®";
    let desc =
      "Aprende y vive la intensidad de apostar al baloncesto 🏀 Conoce más acerca de nuestras cuotas deportivas ¡Vive la experiencia Codere!";

    let can = "https://www.codere.bet.ar/cuotas-deportivas/apuestas-baloncesto";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Qué es back-to-back en básquetbol?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Este término es frecuente en el básquet, cuando se quiere hablar de partidos consecutivos o dos jugadas consecutivas para tomar la posesión de la pelota dentro de un mismo juego.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuándo juega la selección argentina de basquet?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "La selección argentina de básquet juega en diferentes ocasiones durante el año, principalmente en competiciones internacionales como la Copa del Mundo de la FIBA, los Juegos Olímpicos, y el Campeonato FIBA Américas. Además, participa en torneos amistosos y partidos de preparación. Te recomendamos consultar el calendario oficial de la selección Argentina de Básquet, para conocer las fechas y horarios exactos de sus próximos partidos.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/baloncesto/${
    props.flag ? "M" : "D"
  }-`;

  const top_par = {
    h1: "Guía de Apuestas en Básquetbol, consejos ineludibles ",
    p: "El básquetbol es muy emocionante y las apuestas en este deporte son cada vez más increíbles. En esta guía, te vamos a mostrar cómo apostar en básquet con Codere, explicando tipos de apuestas, estadísticas claves y consejos para acertarle a tus pronósticos. Además, vamos a contestar las preguntas frecuentes. Metete de lleno en el emocionante mundo de las ligas de básquet más importantes del mundo.",
  };

  const table_list = [
    {
      title: "¿Cómo funcionan las apuestas en básquetbol de Codere? ",
      id: "anchor1",
    },
    {
      title: "Tipos de apuestas en Baloncesto ",
      id: "anchor2",
    },
    {
      title: "Conocé las Estadísticas principales en Baloncesto ",
      id: "anchor3",
    },
    {
      title: "Preguntas frecuentes sobre las apuestas de Baloncesto  ",
      id: "anchor4",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "¿Cómo funcionan las apuestas en básquetbol de Codere? ",
    p: "Dentro de las opciones para apostar más destacadas, encontramos: ganador del partido, ganador del cuarto, ganador de la primera mitad y total de puntos. Sin embargo, también existen opciones más avanzadas como el hándicap, las apuestas en directo, y las cuotas especiales. ",

    subtitles: [
      {
        h3: "Consejos Básicos ",
        p: "Lo más importante que tenés que tener en cuenta para realizar tus apuestas en el básquetbol, es investigar los equipos y seguirlos en su día a día, para así poder comparar las diferentes cuotas. No te dejes llevar por la intuición o solo el fanatismo, tené un presupuesto definido y no apuestes más que eso.",
      },
      {
        h3: "Rotaciones y descansos de los jugadores clave ",
        p: "En este hermoso deporte, tenemos características claves: tené en cuenta que existen rotaciones ofensivas y defensivas que permiten definir los roles de los jugadores dentro del campo de juego, tanto para anotar como para defender sus propios aros. Para el caso de los tiempos muertos tenemos las variables de tiempos muertos, parciales y el final del encuentro.  ",
      },
      {
        h3: "Bajas de último momento ",
        p: "Tené en cuenta que a veces algunas estrellas o jugadores favoritos hacen que las cuotas estén más bajas porque realmente hacen la diferencia en un equipo, pero pueden sufrir lesiones en el partido, o antes de los encuentros, y ello puede hacer que tu apuesta de un giro de 180 grados. Te recomendamos seguir en vivo el partido y estar pendiente de la previa, eso va a jugar a tu favor. ",
      },
    ],
  };
  const tips = {
    id: "anchor2",
    h2: "Tipos de apuestas en Básquetbol ",
    p: "Existen varios tipos de apuestas en el Básquetbol, aquí te explicaremos los componentes de las más destacadas para armar tu mejor estrategia.",
    subtitles: [
      {
        h3: "Ganador del Partido ",
        p: "Con esta apuesta podés pronosticar qué equipo será el ganador del partido durante su tiempo reglamentario de 48 minutos.  ",
      },
      {
        h3: "Handicap ",
        p: "Gracias al hándicap podés tener una ventaja o desventaja a un equipo en forma de puntos. Por ejemplo, si tu apuesta es a un equipo con un hándicap de +4.5 puntos, ese equipo puede perder hasta por 4 puntos y tu apuesta aún será ganadora.   ",
      },
      {
        h3: "Más/Menos Puntos Totales ",
        p: "En esta apuesta podés indicar los puntos que se anotarán durante el juego, independientemente del resultado de los equipos. Hay desde más/menos de 225.5, hasta más/menos 227.5.",
      },
      {
        h3: "Anticipadas ",
        p: "La apuesta anticipada tiene diferentes diferentes ítems y depende de cada campeonato o liga mundial. Por ejemplo, en la NBA, podés elegir qué equipo será el próximo ganador del campeonato, quién será el ganador de la “Conferencia Este”, “Conferencia Dvision Sureste”, “Conferencia Oeste” y “División noroeste”. La idea es predecir un campeón antes de que termine el campeonato.",
      },
      {
        h3: "Especiales ",
        p: "En las apuestas Especiales, encontrarás diferentes opciones para las distintas ligas. Por ejemplo, en la NBA se puede elegir el MVP de la temporada regular o el entrenador del año.",
      },
      {
        h3: "Triples ",
        p: 'En cada partido, podés acceder a la sección de "Triples". En esta emocionante sección, encontrás diferentes jugadores de ambos equipos y podrás apostar a la cantidad aproximada de triples que realizarán. Por ejemplo, puedes apostar a que Brandon Miller hará más de 2.5 o menos de 2.5 triples, y cada opción tendrá sus propias cuotas.',
      },
      {
        h3: "Asistencias ",
        p: 'En cada partido, podés ir a la sección de "Asistencias". Allí, verás varios jugadores de ambos equipos para apostar sobre cuántas asistencias harán.',
      },
    ],
  };

  const como_hacer = {
    id: "anchor3",
    h2: "Conocé las Estadísticas principales en básquetbol  ",
    p: "Convertite en un especialista de este increíble deporte. Informate, buscá información sobre los jugadores, los últimos encuentros. Conocé las estadísticas claves del básquetbol: puntos, rebotes, asistencias y más. Domina el juego con información esencial para que tus apuestas se multipliquen en ganancias.",
  };
  const preguntas = {
    id: "anchor4",
    h2: "Preguntas frecuentes ",
    inner: [
      {
        h3: "¿Qué es back-to-back en básquetbol? ",
        p: "Este término es frecuente en el básquet, cuando se quiere hablar de partidos consecutivos o dos jugadas consecutivas para tomar la posesión de la pelota dentro de un mismo juego.   ",
      },
      {
        h3: "¿Cuándo juega la selección argentina de basquet? ",
        p: "La selección argentina de básquet juega en diferentes ocasiones durante el año, principalmente en competiciones internacionales como la Copa del Mundo de la FIBA, los Juegos Olímpicos, y el Campeonato FIBA Américas. Además, participa en torneos amistosos y partidos de preparación. Te recomendamos consultar el calendario oficial de la selección Argentina de Básquet, para conocer las fechas y horarios exactos de sus próximos partidos. ",
      },
    ],
  };

  return (
    <>
      <Floating text="Encesta con la NBA" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/baloncesto/${
            props.flag ? "M" : "D"
          }-Header.webp)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event">
          <Container>
            <h1 className="header subtitle">{top_par.h1}</h1>
            <p>{top_par.p}</p>
            {!props.flag && <TableOfContents table={table_list} />}

            <div className="mt-4" id={history.id}>
              <h2 className="subtitle">{history.h2}</h2>
            </div>
            <p>{history.p}</p>

            {history.subtitles.map((inn, k) => (
              <div key={k}>
                <h3 className="subtitle">{inn.h3}</h3>
                <p>{inn.p}</p>
              </div>
            ))}

            <div className="mt-4" id={tips.id}>
              <h2 className="subtitle">{tips.h2}</h2>
              <p>{tips.p}</p>
              {tips.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.p}</p>
                </div>
              ))}
            </div>
            <div className="mt-5" id={como_hacer.id}>
              <h2 className="subtitle">{como_hacer.h2}</h2>
              <p>{como_hacer.p}</p>
            </div>
            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>{inn.p}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + "Basketball.jpg"} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
