import { Button, Container, Card, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import Floating from "../Floating";
import { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../App";

export default function CuotasDeportivos(props) {
  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/assets/seo/sport/CuotasDeportivos/`;

  useEffect(() => {
    const title =
      "Viví las mejores Cuotas Deportivas » Bono 200.000 | Codere®";
    const desc =
      "Conoce lo más importante en Cuotas de Apuestas de tus competiciones 🏆 y deportes favoritos en Codere, la casa de Apuestas más grande de Argentina.";

    let can = "https://www.codere.bet.ar/cuotas-deportivas";

    CodereHelmet(title, desc, can, []);
  }, []);

  const data = {
    top_games: [
      {
        img: "tenis-promo",
        url: "apuestas-tenis",
        h2: "Apuestas de Tenis",
        game: "Tenis",
      },
      {
        img: "nfl-promo",
        url: "apuestas-nfl-futbol-americano",
        h2: "Apuestas de NFL",
        game: "NFL",
      },
      {
        img: "mlb-promo",
        url: "apuestas-beisbol-grandes-ligas",
        h2: "Apuestas de MLB",
        game: "MLB",
      },
      {
        img: "futbol-promo",
        url: "apuestas-futbol",
        h2: "Apuestas de Fútbol",
        game: "Fútbol",
      },
      {
        img: "f1-promo",
        url: "apuestas-formula1",
        h2: "Apuestas de F1",
        game: "F1",
      },
      {
        img: "ciclismo-promo",
        url: "apuestas-ciclismo",
        h2: "Apuestas de Ciclismo",
        game: "Ciclismo",
      },
      {
        img: "balenco-promo",
        url: "apuestas-baloncesto",
        h2: "Apuestas de Baloncesto",
        game: "Baloncesto",
      },
      {
        img: "ufc-promo",
        url: "apuestas-ufc",
        h2: "Apuestas de UFC",
        game: "UFC",
      },
    ],
  };

  const cardGenerator = (g, i) => {
    return (
      <Col md={4} xs={6} key={i}>
        <Card className={`eventos_card`}>
          <Link
            className="text-decoration-none"
            to={`/cuotas-deportivas/${g.url}`}
          >
            <Card.Header as={"h2"}>{!props.flag ? g.h2 : g.game}</Card.Header>
          </Link>
          <Card.Body>
            <LazyLoadImage src={prefix + g.img + ".webp"} />
          </Card.Body>
          <Card.Footer>
            <Link rel="nofollow" to={g.url}>
              <Button
                as="span"
                className="ev-button"
                style={{
                  width: "100%",
                  fontWeight: 600,
                  borderRadius: "1rem",
                }}
              >
                VER INFORMACIÓN
              </Button>
            </Link>
          </Card.Footer>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div id="eventos">
        <Floating text="CUOTAS DEPORTIVAS" />
        <div
          className="top-bg-seo"
          style={{
            backgroundImage: `url(${prefix}general-${
              !props.flag ? "desktop" : "mobile"
            }.webp)`,
            backgroundSize: "cover",
          }}
        ></div>

        <h1 style={{ color: "#fff", textAlign: "center", padding: "1.5rem" }}>
          Las mejores Cuotas Deportivas están en Codere
        </h1>
        <Container className="eventos-container">
          <Row>
            {data.top_games.map((g, i) => {
              return cardGenerator(g, i);
            })}
          </Row>
        </Container>
      </div>
    </>
  );
}
