import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";

export default function Futbol(props) {
  useEffect(() => {
    let title =
      "Vive las Apuestas Online de Fútbol » Bono $200.000  | Codere®";
    let desc =
      "Además, te vamos a ofrecer consejos y tácticas para apostar como un experto. No te pierdas esta guía sobre apuestas en fútbol ⚽︎ y vive la expericnia Codere";

    let can = "https://www.codere.bet.ar/cuotas-deportivas/apuestas-futbol";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Qué es el handicap en apuestas de fútbol?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Se establece un resultado ficticio para nivelar la fuerza de los equipos. Podés apostar a que un equipo va a ganar con una ventaja o va a perder por una cantidad determinada de goles.",
          },
        },
        {
          "@type": "Question",
          name: "¿Puedo apostar en vivo durante un partido desde Argentina?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Sí, en Codere te ofrecemos la opción de apostar en vivo en eventos deportivos, incluidos los partidos de fútbol. Podés hacer apuestas mientras el partido se está desarrollando, aprovechando las cuotas que cambian en tiempo real.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/futbol/${
    props.flag ? "M" : "D"
  }-Soccer-`;
  const jpg = ".jpg";

  const top_par = {
    h1: "Guía de apuestas fútbol, lo que debes conocer",
    p: "En esta guía vas a descubrir toda la información que necesitás sobre las apuestas deportivas en el fútbol y todos los datos imprescindibles para hacerlas.",
    p1: "Preparate para aprender sobre los diferentes tipos de apuestas disponibles, cómo calcular las cuotas y cómo evaluar a los equipos y jugadores antes de apostar. Además, te vamos a ofrecer consejos y tácticas para que aumentes tus probabilidades de ganar. ¡No te pierdas esta guía perfecta sobre las apuestas en el fútbol!",
  };

  const table_list = [
    {
      title: "¿Cómo funcionan las apuestas de Fútbol?",
      id: "anchor1",
    },
    {
      title: "Tipos de apuestas de futbol en Codere Argentina",
      id: "anchor2",
    },
    {
      title: "Consejos de Codere para apuestas de futbol",
      id: "anchor3",
    },
    {
      title: "Preguntas Frecuentes",
      id: "anchor4",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "¿Cómo funcionan las apuestas de Fútbol? ",
    p: "Para hacer una apuesta de fútbol segura y confiable, deberás familiarizarte con las reglas de este deporte, investigar y analizar cada jugada, establecer un presupuesto específico y comprender los diferentes tipos de apuestas existentes en esta materia. ",

    subtitles: [
      {
        h3: "Conocé las Cuotas (Momios) ",
        p: 'Las cuotas representan la probabilidad percibida de que ocurra un evento. Cuotas más bajas indican que un resultado es más probable, mientras que cuotas más altas sugieren que es menos probable. Por ejemplo, si un equipo es favorito para ganar un partido, tendrá cuotas más bajas en el mercado de "ganador del partido".',
      },
    ],
  };

  const tips = {
    id: "anchor2",
    h2: "Tipos de apuestas de fútbol en Codere Argentina ",
    p: "En Codere, te ofrecemos una gran variedad de ofertas para que elijas la que más te convenga. Podés apostar al 1x2, a la doble oportunidad, al número de goles, al resultado exacto y mucho más. No te pierdas la emoción de los mejores eventos de fútbol y aprovechá nuestras cuotas y promociones especiales para los eventos seleccionados. ",
    subtitles: [
      {
        h3: "Apuesta 1X2  ",
        p: "Esta modalidad evalúa el resultado de un partido desde una perspectiva sencilla. En este tipo de juego, se consideran tres posibles resultados: la victoria del equipo local, el triunfo del equipo visitante o un empate entre ambos equipos. Por lo tanto, al seleccionar esta opción, estás pronosticando quién será el ganador del partido o si el juego terminará en empate.",
      },
      {
        h3: "Doble Oportunidad  ",
        p: "Esta modalidad quizá necesita una estrategia más profesional o ingeniosa para aumentar tus posibilidades de ganar como un crack, te permite elegir dos de las tres opciones: victoria local, empate, victoria visitante acompañada de empate, o incluso apuesta a victoria local y visitante. Así,  tenés dos oportunidades de acertar y maximizás las posibilidades de obtener ganancias.  ",
      },
      {
        h3: "Resultado Final ",
        p: 'Una forma de aumentar tus posibilidades de ganar en las apuestas deportivas es usar la opción de "resultado final", que te permite elegir varios marcadores posibles para un mismo partido y combinarlos en un solo ticket. Así, si acertás alguno de los marcadores, ganás la apuesta. Ejemplo, si creés que el equipo “A” va a ganar al equipo “B”, pero no estás seguro de los goles que se van a anotar, podés apostar por los resultados 1-0, 2-0 y 3-0 a tu favorito. Si el partido termina con alguno de esos marcadores, tu ticket será ganador.    ',
      },
      {
        h3: "Más/Menos Total Goles  ",
        p: "En esta sección se puede apostar a la cantidad de goles que va a haber en el encuentro, van desde más o menos goles: 0,5 - 1,5 – 2.5 - 3,5 – 4,5. Aquí hay que ser muy estratégico y seguir a los equipos muy de cerca para ver las probabilidades antes de apostar. ",
      },
      {
        h3: "Marcan Ambos Equipos   ",
        p: 'En la opción "ambos marcan" consiste en predecir que los dos equipos que se enfrentan lograrán marcar, al menos, un gol, durante el partido. No importa si el resultado final es un empate, una victoria por la mínima, ni tampoco interesa la cantidad de goles que conviertan. Con convertir ambos equipos al menos un gol, ya es válida. ',
      },
      {
        h3: "Resultado Final (Múltiples Resultados)  ",
        p: 'En esta modalidad de apuestas, los jugadores pueden apostar en diferentes resultados al mismo tiempo, pero en secciones que ellos eligen. Esto se distingue de las apuestas combinadas múltiples, que se explicarán más adelante. Por ejemplo, en la categoría de resultado final, es posible apostar a que el equipo "A" o "B" ganará por 1:0, 2:0 o 3:0. Cada opción en esta sección tendrá una cuota asociada.',
      },
      {
        h3: "Combinadas (Múltiples Resultados)  ",
        p: 'A diferencia de la apuesta de "Resultado Final con Múltiples Resultados", en las apuestas combinadas podés elegir la cantidad de resultados que desees, y cada uno de ellos tiene su propia cuota. Por ejemplo, podés apostar a que el equipo "A" o "B" gana o pierde por 1:0, mientras simultáneamente apuestas a otro resultado como 3:1 o 4:2, y así sucesivamente en diferentes resultados aleatorios, cada uno con su cuota respectiva.',
      },
    ],
  };

  const como_hacer = {
    id: "anchor3",
    h2: "Consejos de Codere para apuestas de futbol  ",
    p:
      "Es importante recordar que las apuestas deportivas conllevan riesgos y no hay garantías de ganancias. Siempre apostá con responsabilidad y solo utilizá el" +
      " dinero que puedas permitirte perder. Es recomendable informarte bien sobre el fútbol y los equipos antes de realizar apuestas, y si tenés dudas, buscá asesoramiento en fuentes confiables o profesionales en la materia.",
    subtitles: [
      {
        h3: "Conocé el estado de ánimo de los equipos  ",
        p: "Claramente lo más importante en las apuestas deportivas es el análisis deportivo. Para que tus apuestas tengan más efectividad en cuanto a probabilidades de resultados y no queden solo al azar, es importante analizar tu equipo al máximo, por ejemplo ver el lugar que ocupan en la tabla, ver si en este partido les daría una clasificación a otra competencia, y como vienen al menos sus últimos tres partidos. Algo muy importante es saber si hay jugadores estrellas que estén lesionados o también ver si hubo bajas extrañas en el último tiempo. Analizar si su DT está comprometido con el equipo o estuvo en las noticias en el último tiempo.",
      },
      {
        h3: "Consulta los partidos anteriores en los que se enfrentaron ambos equipos  ",
        p: "El historial de partidos te puede dar una idea de las probabilidades de quién ganará. Es importante ver cuantas veces se enfrentaron los equipos y también en que competencias. El contexto es importante, esto te puede dar una claridad a la hora de apostar por un equipo o por otro.",
      },
      {
        h3: "Tené en cuenta si el equipo juega en casa o fuera  ",
        p: "Mayormente hay equipos que son muy fuertes de local, ya que con su público alentando a su equipo les dá un plus a la hora de jugar y desarrollar un encuentro. El famoso “Son fuertes en casa”. Muchas apuestas pagan menos para los locales, pero las probabilidades de ganar son más altas.",
      },
    ],
  };
  const preguntas = {
    id: "anchor4",
    h2: "Preguntas Frecuentes sobre apuestas en fútbol  ",
    inner: [
      {
        h3: "¿Qué es el handicap en apuestas de fútbol?  ",
        p: "Se establece un resultado ficticio para nivelar la fuerza de los equipos. Podés apostar a que un equipo va a ganar con una ventaja o va a perder por una cantidad determinada de goles.",
      },
      {
        h3: "¿Puedo apostar en vivo durante un partido desde Argentina? ",
        p: "Sí, en Codere te ofrecemos la opción de apostar en vivo en eventos deportivos, incluidos los partidos de fútbol. Podés hacer apuestas mientras el partido se está desarrollando, aprovechando las cuotas que cambian en tiempo real.",
      },
    ],
  };

  return (
    <>
      <Floating text="Apuestas de Fútbol" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/futbol/${
            props.flag ? "M" : "D"
          }-Header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event">
          <Container>
            <h1 className="header subtitle">{top_par.h1}</h1>
            <p>{top_par.p}</p>
            <p>{top_par.p1}</p>
            {!props.flag ? <TableOfContents table={table_list} /> : <></>}

            <div id={history.id}>
              <h2 className="subtitle">{history.h2}</h2>
            </div>
            <p>{history.p}</p>

            {history.subtitles.map((inn, k) => (
              <div key={k}>
                <h3 className="subtitle">{inn.h3}</h3>
                <p>{inn.p}</p>
              </div>
            ))}

            <div className="mt-4" id={tips.id}>
              <h2 className="subtitle">{tips.h2}</h2>
              <p>{tips.p}</p>
              {tips.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.p}</p>
                </div>
              ))}
            </div>
            <div className="mt-5" id={como_hacer.id}>
              <h2 className="subtitle">{como_hacer.h2}</h2>
              <p>{como_hacer.p}</p>
              {como_hacer.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.p}</p>
                </div>
              ))}
            </div>
            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>{inn.p}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + "1" + jpg} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
