import React from "react";
import { Col, NavLink, Image } from "react-bootstrap";
import { SPEEDSIZE_PREFIX } from "../../../App";

const LiveGamesDisplay = ({ live_games, device }) => {
  const game_prefix =
    "https://m.caba.codere.bet.ar/deportes/#/casino/CasinoPage?lobby=ArgentinaCaba&playgame=";
  const baseUrl = "https://www.codere.bet.ar/lobby_tiles/";
  const imgSuffix = "_Square.jpg";

  return (
    <div className="row casino-row">
      <div className={"inicioDesclGamesLine"}>
        Disponible únicamente dentro de CABA
      </div>

      {live_games
        .slice(0, !device ? live_games.length : 4)
        .map((game, index) => {
          const imgUrl = `${baseUrl}${game.name.replace(
            /\s+/g,
            ""
          )}${imgSuffix}`;

          return (
            <Col lg={2} md={4} xs={6} key={index}>
              <NavLink rel="nofollow" href={`${game_prefix}${game.name}`}>
                <div className="cas-game-wrapper">
                  <Image
                    fluid
                    className="codereGameImg"
                    src={`${SPEEDSIZE_PREFIX}${imgUrl}`}
                    alt={game.name}
                  />
                  <div className="hvr">
                    <p>{game.name}</p>
                    <Image
                      className={"codereGameImg"}
                      src={`${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/assets/seo/jackpotsPlayHoverLogo.svg`}
                      alt="Play"
                    />
                  </div>
                </div>
              </NavLink>
            </Col>
          );
        })}
    </div>
  );
};

export default LiveGamesDisplay;
