import "@fontsource/roboto-condensed";
import "@fontsource/roboto";
import { Outlet } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import StickyFooter from "./Components/StickyFooter";
import { isMobile } from "react-device-detect";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import Routing from "./routes/Routing";
import CabaMendozaPopup from "./Components/CabaMendozaPopup";
export const SPEEDSIZE_PREFIX = `https://ecdn.speedsize.com/146a650b-0738-4cda-9854-934a12c53a89/`;
// import Axios from "axios";

export function CodereHelmet(title, description, canonical, jsons) {
  const head = document.head;

  /* init */
  let x, y, z;
  x = document.querySelector('meta[name="description"]');
  y = document.querySelector('link[rel="canonical"]');
  z = document.querySelector("title");

  if (x) [x, y, z].forEach((node) => node && node.parentNode.removeChild(node));

  /* Title */
  const titleEl = document.createElement("title");
  titleEl.textContent = title;

  /* Description */
  const mdes = document.createElement("meta");
  mdes.setAttribute("name", "description");
  mdes.setAttribute("content", description);

  /* Canonical */
  var canonicalLink = document.createElement("link");
  canonicalLink.setAttribute("rel", "canonical");
  canonicalLink.setAttribute("href", canonical);

  // Update JSON-LD structured data
  const jsonLD = document.querySelectorAll(
    'script[type="application/ld+json"]'
  );

  jsonLD.forEach((json) => {
    json.remove();
  });

  jsons.forEach((json) => {
    let tempJson = document.createElement("script");
    tempJson.type = "application/ld+json";
    tempJson.textContent = JSON.stringify(json);

    head.insertBefore(tempJson, head.firstChild);
  });

  head.insertBefore(canonicalLink, head.firstChild);
  head.insertBefore(mdes, head.firstChild);
  head.insertBefore(titleEl, head.firstChild);

  // useHelmet(description, title);
}

export const regis =
  "https://m.caba.codere.bet.ar/deportes/#/mso/RegistroARPage";
export function dateInBetween(banner) {
  let curDate = new Date();

  return (
    curDate >= new Date(banner.startDate) && curDate <= new Date(banner.endDate)
  );
}

function App() {
  const [device, setDevice] = useState(isMobile);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [isPopupVisible, setPopupVisible] = useState(false);

  // const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    if (windowSize.width <= 768 || isMobile) setDevice(true);
    else setDevice(false);
    // console.log("isMobile");
  }, [windowSize]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="App">
      <Header regis={regis} device={device} />
      <CookieConsent
        cookieName="codere_cookie"
        expires={365}
        buttonText="- Aceptar -"
        buttonClasses="cookie-btn"
        cookieValue={"accepted"}
        extraCookieOptions={{ domain: ".codere.bet.ar" }}
        overlayClasses="cookie-overlay"
        containerClasses="cookie-container"
        contentClasses="cookie-content"
      >
        <h3>Uso Cookies</h3>
        Utilizamos cookies propias y de terceros para mejorar tu accesibilidad,
        personalizar, analizar tu navegación, así como para mostrar anuncios
        basados en tus intereses. Si sigues navegando, consideramos que aceptas
        su uso. Podés obtener más información en nuestra{" "}
        <a href="https://www.codere.bet.ar/informaci%C3%B3n-legal/politica-de-cookies">
          política de cookies
        </a>
        .
      </CookieConsent>
      <Routing device={device} />
      <Outlet />
      {device && <StickyFooter />}
      <Footer />
      <CabaMendozaPopup />
    </div>
  );
}

export default App;
