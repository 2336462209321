import { Button } from "react-bootstrap";

export default function Floating(props) {
  return (
    <div className="floating">
      <span>{props.text}</span>
      <br />
      <Button
        href={
          props.juega
            ? "https://m.caba.codere.bet.ar/deportes/#/casino/CasinoPage?lobby=ArgentinaCaba "
            : "https://m.caba.codere.bet.ar/deportes/#/mso/RegistroARPage"
        }
        //
        rel="nofollow"
        className="floating-btn"
      >
        {props.juega ? "Jugá Ahora" : "Registrate"}
      </Button>
    </div>
  );
}
