import gameCSS from "../CSS/games.module.css";
import TopGame from "./TopGame";
import BottomGame from "./BottomGame";
import GameSwiper from "./GameSwiper";
import gameCarousel from "../JSON/mob-games.json";
import deskGames from "../JSON/desktop-games.json";

// import { isMobile } from "react-device-detect";
// Top Games \\

export function replaceSpanishCharacters(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function Games({ device }) {
  const desktopTitleStyles = {
    color: "#fff",
    fontSize: "1.75rem",
    fontWeight: 700,
  };
  const mobileTitleStyles = {
    color: "#79c000",
    fontWeight: 700,
    textAlign: "center",
    fontSize: "5vw",
    margin: "1.75vw 0",
  };

  const titles = ["Juegos Destacados", "Nuevos Juegos"];
  return (
    <>
      {device ? (
        <div id="mobile-games-section">
          {gameCarousel.map((games, k) => (
            <div key={k}>
              <div  className={'mb-2'} style={mobileTitleStyles}>
                <span>{titles[k]}</span>
                <div className={'inicioDesclGamesLine  inicioDesclGamesLineFSize'}>Disponible únicamente dentro de CABA</div>
              </div>
              <GameSwiper games={games} order={k} />
            </div>
          ))}
        </div>
      ) : (
        <div id={gameCSS.desktop_games}>
          <div>
            <span style={desktopTitleStyles}>Mejores Juegos</span>
            <div className={'inicioDesclGamesLine'}>Disponible únicamente dentro de CABA</div>
          </div>
          <div className={gameCSS.top_games_conatiner}>
            {deskGames.top_games.map((game, key) => (
              <div key={key}>
                <TopGame game={game} />
              </div>
            ))}
          </div>
          <div  className={'mb-3'}>
            <span style={desktopTitleStyles}>Nuevos Juegos</span>
            <div className={'inicioDesclGamesLine'}>Disponible únicamente dentro de CABA</div>
          </div>

          <div className={gameCSS.bottom_conatiner}>
            {deskGames.bottom_games.map((game, _) => {
              return (
                <div key={game.name}>
                  <BottomGame game={game} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default Games;
