import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container, ListGroup } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";

export default function UFC(props) {
  useEffect(() => {
    let title = "Descubre las Apuestas en UFC » Bono $200.000  | Codere®";
    let desc =
      "En esta guía vas a conocer con más detalle acerca de la UFC 🤼 y cómo podrás darle más emoción a tus pronósticos deportivos en este deporte de combate.";

    let can = "https://www.codere.bet.ar/cuotas-deportivas/apuestas-ufc";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Dónde ver el calendario de peleas de la UFC?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "El calendario completo de la UFC lo podrás encontrar en el sitio web oficial de la Ultimate Fighting Championship o en la aplicación móvil de la organización.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuál es la diferencia entre MMA y UFC?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "MMA (Artes Marciales Mixtas): Es el deporte que mezcla distintas técnicas de lucha, tanto de pie como en el suelo, sacadas de diferentes disciplinas. Se practica en varias competencias alrededor del mundo. UFC (Ultimate Fighting Championship): Es la organización más importante y reconocida que organiza eventos de MMA. Presenta peleas y campeonatos de MMA a nivel global, siendo clave en la popularización del deporte.",
          },
        },
        {
          "@type": "Question",
          name: "¿Dónde ver las peleas de UFC en Argentina?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Tanto para Latinoamérica como para Argentina, los combates se pueden ver a través de las plataformas de streaming de la UFC, así como también están disponibles a través de plataformas por suscripción que tengan los derechos de transmisión.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/ufc/${
    props.flag ? "M" : "D"
  }-UFC-1`;
  const jpg = ".jpg";

  const top_par = {
    h1: "Guía de apuesta en la UFC, aumenta la emoción",
    p: "En esta guía vas a conocer con más detalle todo lo relacionado con la UFC y cómo podrás darle más emoción a tus pronósticos deportivos en este deporte de combate.   ",
  };

  const table_list = [
    {
      title: "Conoce la UFC",
      id: "anchor1",
    },
    {
      title: "Top de peleadores",
      id: "anchor2",
    },
    {
      title: "Top Femenil",
      id: "anchor3",
    },
    {
      title: "¿Cómo funcionan las apuestas en la UFC de Codere?",
      id: "anchor4",
    },
    {
      title: "Tips para apostar",
      id: "anchor5",
    },
    {
      title: "Preguntas Frecuentes",
      id: "anchor6",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "Conoce la UFC",
    p: "La UFC, o Ultimate Fighting Championship, es una organización de artes marciales mixtas (MMA), que está considerada como la principal promotora de este deporte, y que ha tomado fuerza con el paso de los años. En estas peleas, los competidores combinan técnicas de diversas disciplinas de combate, como boxeo, kickboxing, lucha libre, jiu-jitsu brasileño y judo, creando un estilo de lucha dinámico y emocionante.  ",

    subtitles: [
      {
        h3: "Top de Masculino",
        p:
          "Conocé a los titanes de la UFC en la categoría masculina. Con nombres como Kamaru Usman, Alexander Volkanovski e Israel Adesanya, estos peleadores" +
          " dejaron una marca imborrable en el octágono.",
        list: [
          {
            liTitle: "Kamaru Usman: ",
            liText:
              "De origen nigeriano, Usman es el actual campeón del peso welter y ostenta un récord de 20-1-0 y hoy por hoy se le considera como uno de los peleadores más fuertes de la UFC en la historia, gracias a su estilo completo en el octágono.  ",
          },
          {
            liTitle: "Alexander Volkanovski: ",
            liText:
              "Al luchador australiano, se le considera dominante en el campo de batalla y se ha hecho sentir gracias a sus números de 25-1-0 en la categoría de peso pluma.  ",
          },
          {
            liTitle: "Israel Adesanya: ",
            liText:
              "Sus estadísticas de 23-1-0 lo sitúan como el peleador nigeriano más creativo y letal de la UFC, en la categoría de peso medio.  ",
          },
        ],
      },
      {
        h3: "Top Femenil",
        id: "anchor3",
        p: "Con una destreza y valentía excepcionales, las peleadoras destacadas en este Top desafían los límites y demuestran su habilidad en el octágono.",
        list: [
          {
            liTitle: "Amanda Nunes: ",
            liText:
              "La brasileña es campeona en dos categorías de las artes marciales mixtas, peso gallo y peso pluma, con un récord de 21-5. Se proyecta como una leyenda viviente de la disciplina.",
          },
          {
            liTitle: "Valentina Shevchenko: ",
            liText:
              "Originaria de Kirguistán, esta luchadora se destaca en la categoría de peso mosca y tiene un récord de 23-3.",
          },
          {
            liTitle: "Julianna Peña: ",
            liText:
              "La estadounidense de origen venezolano es una de las peleadoras más fuertes gracias a su juego de golpes y sumisiones, lo que le ha permitido no solo tener un récord de 11-4, sino también el campeonato interino de peso pluma.",
          },
        ],
      },
    ],
  };

  const tips = {
    id: "anchor4",
    h2: "¿Cómo funcionan las apuestas en la UFC de Codere? ",
    p: "En nuestra oferta para la UFC figuran dos tipos de apuesta con los que podrás vivir la experiencia de las artes marciales mixtas desde tu celular:",
    subtitles: [
      {
        h3: "Ganador del Combate ",
        p: "Aquí se elige al que creas ganador de la pelea. Es tu oportunidad de respaldar a tu favorito y anticipar quién se alzará triunfante en el enfrentamiento.",
      },
      {
        h3: "Más o menos cantidad de asaltos  ",
        p: "Aquí podrás anticipar la duración del combate en términos de asaltos. El promedio de asaltos es de 2,5 rounds, lo que te permite pronosticar la cantidad de asaltos que durará el combate.  ",
      },
    ],
  };

  const como_hacer = {
    id: "anchor5",
    h2: "Tips para apostar ",
    p: "El juego moderado es parte de una buena experiencia con el juego, así que te recomendamos estos tips para apostar correctamente:",
    list: [
      {
        liText:
          "Antes que nada, investigar a los peleadores, estudiar los estilos de lucha y las estadísticas de ellos.   ",
      },
      {
        liText: "Comprender muy bien las líneas de apuesta.  ",
      },
      {
        liText:
          "Establecer un presupuesto y mantener la calma a la hora de apostar.   ",
      },
    ],
  };

  const preguntas = {
    id: "anchor6",
    h2: "Preguntas frecuentes sobre el UFC ",
    inner: [
      {
        h3: "¿Dónde ver el calendario de peleas de la UFC? ",
        p: "El calendario completo de la UFC lo podrás encontrar en el sitio web oficial de la Ultimate Fighting Championship o en la aplicación móvil de la organización.  ",
      },
      {
        h3: "¿Cuál es la diferencia entre MMA y UFC? ",
        data: [
          {
            dTitle: "MMA ",
            dText:
              "(Artes Marciales Mixtas): Es el deporte que mezcla distintas técnicas de lucha, tanto de pie como en el suelo, sacadas de diferentes disciplinas. Se practica en varias competencias alrededor del mundo.",
          },
          {
            dTitle: "UFC ",
            dText:
              "(Ultimate Fighting Championship): Es la organización más importante y reconocida que organiza eventos de MMA. Presenta peleas y campeonatos de MMA a nivel global, siendo clave en la popularización del deporte.",
          },
        ],
      },
      {
        h3: "¿Dónde ver las peleas de UFC en Argentina? ",
        p: "Tanto para Latinoamérica como para Argentina, los combates se pueden ver a través de las plataformas de streaming de la UFC, así como también están disponibles a través de plataformas por suscripción que tengan los derechos de transmisión.",
      },
    ],
  };

  return (
    <>
      <Floating text="Apuestas de UFC" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/ufc/${
            props.flag ? "M" : "D"
          }-Header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event">
          <Container>
            <h1 className="header subtitle">{top_par.h1}</h1>
            <p>{top_par.p}</p>
            {!props.flag ? <TableOfContents table={table_list} /> : <></>}

            <div id={history.id}>
              <h2 className="subtitle">{history.h2}</h2>
              <p>{history.p}</p>

              {history.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 id={inn.id} className="subtitle">
                    {inn.h3}
                  </h3>
                  <p>{inn.p}</p>
                  <ol>
                    {inn.list.map((item, i) => (
                      <li key={i} className={"mb-1"}>
                        <strong>{item.liTitle}</strong>
                        {item.liText}
                      </li>
                    ))}
                  </ol>
                </div>
              ))}
            </div>

            <div className="mt-4" id={tips.id}>
              <h2 className="subtitle">{tips.h2}</h2>
              {tips.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.p}</p>
                </div>
              ))}
            </div>
            <div className="mt-5" id={como_hacer.id}>
              <h2 className="subtitle">{como_hacer.h2}</h2>
              <p>{como_hacer.p}</p>

              <ul className={"mx-5"}>
                {como_hacer.list.map((item, i) => (
                  <li key={i} className={"mb-1"}>
                    {item.liText}
                  </li>
                ))}
              </ul>
            </div>
            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inner, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inner.h3}</Accordion.Header>
                    <Accordion.Body>
                      {inner.p && <p>{inner.p}</p>}
                      {inner.data &&
                        inner.data.map((item, i) => (
                          <p key={i} className={"mb-1"}>
                            <strong>{item.dTitle}</strong>
                            {item.dText}
                          </p>
                        ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + jpg} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
