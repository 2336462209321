import { useEffect } from "react";
import Floating from "../../Floating";
import { Accordion, Container } from "react-bootstrap";
import TableOfContents from "../../TableOfContents";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";

export default function Tenis(props) {
  useEffect(() => {
    let title = "Aprende Apostar al Tenis » Bono $200.000  | Codere®";
    let desc =
      "En esta guía encontrarás la información necesaria para realizar apuestas en Tenis 🎾de forma efectiva y profesional para vivir la máxima expericia Codere.";
    let can = "https://www.codere.bet.ar/cuotas-deportivas/apuestas-tenis";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Qué campeonato de tenis se juega en Londres?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "El campeonato de tenis que se juega en Londres es Wimbledon. Es uno de los torneos más importantes del mundo y se lleva a cabo en el All England Lawn Tennis and Croquet Club, ubicado en el barrio de Wimbledon, en Londres. Se destaca por ser el torneo de tenis más antiguo y prestigioso, y se disputa sobre césped a finales de junio y principios de julio cada año.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cómo se llama la copa de tenis femenino?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "La copa de tenis femenino más importante se llama el WTA Finals, o también conocida como el Torneo de Finales de la WTA. En este evento, las ocho mejores jugadoras del año se enfrentan para determinar quién será la campeona del año. Es uno de los eventos más destacados en el tenis femenino profesional.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/tenis/${
    props.flag ? "M" : "D"
  }-Tennis-`;
  const jpg = ".jpg";

  const top_par = {
    h1: "Guía de apuestas en Tenis, aprendé cómo funcionan ",
    p: "Con Codere tenés múltiples formas de ganar dinero con el tenis. En esta guía encontrarás la información necesaria para realizar tus apuestas de la forma más efectiva.   ",
  };

  const table_list = [
    {
      title: "¿Cómo funcionan las apuestas online  de Tenis? ",
      id: "anchor1",
    },
    {
      title: "Principales Torneos de Tenis en el mundo ",
      id: "anchor2",
    },
    {
      title: "Tipos de apuestas  ",
      id: "anchor3",
    },
    {
      title: "Estadísticas y Resultados en Tenis ",
      id: "anchor4",
    },
    {
      title: "Preguntas Frecuentes ",
      id: "anchor4",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "¿Cómo funcionan las apuestas online de Tenis? ",
    p: "Para apostar en tenis, es importante considerar las estadísticas y resultados de los jugadores, como su ranking ATP, desempeño en torneos anteriores, lesiones, y la importancia del partido para ellos. En Codere, podés apostar al ganador del partido, al ganador de cada set, al número total de juegos, si habrá un tiebreak, entre otras opciones. Además, se pueden encontrar diferentes tipos de apuestas especiales, como hándicaps y apuestas exactas, que ofrecen diversas oportunidades para ganar.",
  };

  const tips = {
    id: "anchor2",
    h2: "Principales Torneos de Tenis en el mundo ",
    p: "Los principales torneos del mundo son los 4 gran Slams:  son los eventos más prestigiosos en el mundo del tenis, atrayendo a los mejores jugadores de todo el mundo.",
    list: [
      {
        liTitle: "Australian Open: ",
        liText:
          "inicia la temporada con sus emocionantes partidos en superficie dura en Melbourne.",
      },
      {
        liTitle: "Roland Garros (French Open): ",
        liText:
          "desafía a los jugadores en París con su icónica arcilla roja y su atmósfera única.",
      },
      {
        liTitle: "Wimbledon:  ",
        liText:
          "en Londres, es la cuna del tenis sobre césped, destacando por su tradición y elegancia.",
      },
      {
        liTitle: "US Open: ",
        liText:
          "cierra la temporada con su torneo nocturno único en Flushing Meadows, Nueva York.",
      },
    ],
    boldTextPar: [
      {
        bolded: "Luego pasamos por los Torneos ATP Tour: ",
        text: "el ATP Tour presenta una gama de torneos que van desde los prestigiosos ATP Finals, que reúnen a los mejores jugadores del año en una batalla final por el título, hasta los torneos ATP Masters 1000, que son eventos de élite con una gran cantidad de puntos en juego y una competencia feroz. Los ATP 500 Series y ATP 250 Series ofrecen oportunidades clave para los jugadores en distintas etapas de la temporada, proporcionando un escenario para demostrar su habilidad y competir por puntos y premios en efectivo.",
      },
      {
        bolded: "Por otra parte los WTA Tour: ",
        text: "abarca una serie de competiciones, desde el prestigioso WTA Finals, donde las mejores jugadoras del año se enfrentan en un torneo de élite, hasta los eventos de la serie WTA 1000, WTA 500 y WTA 250, que ofrecen oportunidades valiosas para competir en diferentes niveles y acumular puntos en el ranking mundial.",
      },
    ],
  };

  const como_hacer = {
    id: "anchor3",
    h2: "Tipos de Apuestas  ",
    p: "Hay diferentes tipos de apuestas en Codere. Tenés para elegir al ganador del partido, ganador del primer o segundo set, podes hacer apuestas combinadas (como que el ganador del primer set también será el ganador del encuentro), número total de Sets, si habrá un tiebreak en el partido o no, y mucho más. ",
    subtitles: [
      {
        h3: "Ganador de partido  ",
        text: "Esta es quizá la apuesta más sencilla, es simplemente apostar a quién será el ganador del encuentro, sin importar diferencia de sets o games jugados. ",
      },
      {
        h3: "Ganador el 1er Set ",
        text: "En esta opción vamos a apostar a quién gana el primer set, sin importar el resultado del encuentro. Ver cómo salió en los últimos partidos cada jugador puede ser importante a la hora de apostar.",
      },
      {
        h3: "Ganador del Sert 2 ",
        text: "En esta opción vamos a apostar quién gana el segundo set, sin importar el resultado del encuentro. Es importante analizar si el Torneo es al mejor de 3 o 5 Sets, esto puede ayudarte a descifrar un poco más tu apuesta.",
      },
      {
        h3: "Ganador el 1er Set y Ganar el Partido ",
        text: "En esta opción podés realizar una apuesta en la que el jugador que gane el primer set también se lleve la victoria del partido.",
      },
      {
        h3: "Total de juegos Más/Menos ",
        text: "En estas apuestas, se estima el total de juegos que se jugarán en el partido basándose en el rendimiento previo de los jugadores. Es esencial revisar si los jugadores han tenido partidos largos o cortos en el pasado para tomar decisiones informadas.",
      },
      {
        h3: "Resultado final (por set) ",
        text: "En esta opción, se apuesta al resultado final del encuentro por set, indicando si será un resultado de 2:0 o 2:1 en caso de un partido al mejor de tres sets, permitiendo apostar por cualquiera de los dos jugadores.",
      },
      {
        h3: "Hándicap por Juegos ",
        text: "Para tratar de equilibrar un poco las cuotas, hay un opción que es el hándicap. Se trata de un tipo de apuesta en la que se concede una ventaja o desventaja inicial ficticia ya sea positiva o negativa a un jugador sobre otro. Se utiliza para igualar las probabilidades de ambos equipos. Cada sección tiene su cuota y valor.",
      },
    ],
  };

  const resultados = {
    id: "anchor4",
    h2: "Estadísticas y Resultados en Tenis  ",
    p: "Las estadísticas y resultados en el tenis a la hora de apostar son muy importantes. Antes de cada partido, recomendamos hacer un análisis específico a cada jugador:",
    list: [
      { liText: "¿En qué posición están en el ranking ATP?" },
      { liText: "Cómo fueron sus últimos torneos/partidos." },
      {
        liText:
          "¿Necesita este resultado para clasificar a algún torneo importante?",
      },
      { liText: "¿Viene de alguna Lesión?" },
    ],
  };

  const preguntas = {
    id: "anchor5",
    h2: "Preguntas frecuentes ",
    inner: [
      {
        h3: "¿Qué campeonato de tenis se juega en Londres? ",
        p: "El campeonato de tenis que se juega en Londres es Wimbledon. Es uno de los torneos más importantes del mundo y se lleva a cabo en el All England Lawn Tennis and Croquet Club, ubicado en el barrio de Wimbledon, en Londres. Se destaca por ser el torneo de tenis más antiguo y prestigioso, y se disputa sobre césped a finales de junio y principios de julio cada año.",
      },
      {
        h3: "¿Cómo se llama la copa de tenis femenino?  ",
        p: "La copa de tenis femenino más importante se llama el WTA Finals, o también conocida como el Torneo de Finales de la WTA. En este evento, las ocho mejores jugadoras del año se enfrentan para determinar quién será la campeona del año. Es uno de los eventos más destacados en el tenis femenino profesional.",
      },
    ],
  };

  return (
    <>
      <Floating text="Apuestas de Tennis" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/tenis/${
            props.flag ? "M" : "D"
          }-Header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event">
          <Container>
            <h1 className="header subtitle">{top_par.h1}</h1>
            <p>{top_par.p}</p>
            {!props.flag ? <TableOfContents table={table_list} /> : <></>}

            <div id={history.id}>
              <h2 className="subtitle">{history.h2}</h2>
            </div>
            <p>{history.p}</p>

            <div className="mt-4" id={tips.id}>
              <h2 className="subtitle">{tips.h2}</h2>
              <p>{tips.p}</p>
              <ol className={"mb-3"}>
                {tips.list.map((item, i) => (
                  <li key={i} className={"mb-1"}>
                    <strong>{item.liTitle}</strong>
                    {item.liText}
                  </li>
                ))}
              </ol>

              {tips.boldTextPar.map((item, i) => (
                <p key={i} className={"mb-2"}>
                  <strong>{item.bolded}</strong> {item.text}{" "}
                </p>
              ))}
            </div>

            <div className="mt-5" id={como_hacer.id}>
              <h2 className="subtitle">{como_hacer.h2}</h2>
              <p>{como_hacer.p}</p>
              {como_hacer.subtitles.map((item, i) => (
                <div id={i}>
                  <h3 className={"subtitle"}>{item.h3}</h3>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
            <div className={"mt-5"} id={resultados.id}>
              <h2 className="subtitle">{resultados.h2}</h2>
              <p>{resultados.p}</p>
              <ul className={"mb-3"}>
                {resultados.list.map((item, i) => (
                  <li key={i} className={"mb-1"}>
                    {item.liText}
                  </li>
                ))}
              </ul>
            </div>
            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>{inn.p}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + "1" + jpg} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
