import Floating from "../Floating";
import { Accordion, Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TableOfContents from "../TableOfContents";
import { CodereHelmet, regis } from "../../../App";
import { useEffect } from "react";
import LiveGamesDisplay from "./LiveGamesDisplay";
import { SPEEDSIZE_PREFIX } from "../../../App";

export default function Baccarat(props) {
  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Qué cartas usar para jugar al baccarat?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Para jugar al Baccarat se utilizan cartas estándar de baraja francesa. Las cartas numéricas (2 al 9) conservan su valor nominal, mientras que las figuras (J, Q, K) y el 10 tienen un valor de 0 puntos. El As equivale a 1 punto",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuánto valen las cartas en Baccarat?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "En el Baccarat, las cartas tienen los siguientes valores: Las cartas numéricas (del 2 al 9) conservan su valor nominal. Las cartas con figuras (J, Q, K) y el 10 tienen un valor de 0 puntos. El As tiene un valor de 1 punto.",
          },
        },
      ],
    };

    let title =
      "Baccarat Online » Vive la emoción del juego en línea | Codere®";
    let desc =
      "Apostá por la mano ganadora y disfrutá del Baccarat Online 💰. Tu objetivo es predecir cuál mano, la del jugador o la del crupier, se acercará más al 9.";
    let can = "https://www.codere.bet.ar/casino/baccarat";

    CodereHelmet(title, desc, can, [json]);
  }, []);

  const top_par = {
    h1: "Jugá Baccarat en línea desde tu celular",
    p: [
      "Explorá el fascinante mundo del Baccarat, donde la estrategia se entrelaza con la emoción del azar. Sumergite en la elegancia de este juego de cartas mientras te enfrentás al desafío de vencer al crupier. Tené este apasionante juego en la palma de tu mano, el Baccarat ofrece una experiencia emocionante llena de suspenso y oportunidades de ganar. ¿Estás listo para apostar y sentir la emoción de jugada?",
    ],
  };

  // Table of Contents  \\
  const table_of_contents_list = [
    {
      title: "¿Qué es el Baccarat?",
      id: "anchor1",
    },
    {
      title: "¿Cómo jugar Baccarat online?",
      id: "anchor2",
    },
    {
      title: "Juegos de Baccarat en Codere ",
      id: "anchor3",
    },
    {
      title: "Consejos para jugar",
      id: "anchor4",
    },
    {
      title: "Preguntas frecuentes ",
      id: "anchor5",
    },
  ];

  // Games Data
  const live_games = [
    { name: "EVG Live Baccarat" },
    { name: "EVG Live No Commission Baccarat" },
    { name: "EVG Live Control Squeeze Baccarat" },
    { name: "EVG Live Golden Wealth Baccarat" },
    { name: "EVG Live Speed Baccarat" },
    { name: "EVG Xxxtreme Lightning Baccarat" },
  ];
  const como_juega = {
    id: "anchor1",
    h2: "¿Qué es el Baccarat?",
    p: "El Baccarat es un juego de cartas, que se caracteriza por su elegancia y simplicidad. En este juego, los jugadores apuestan por el resultado de la mano, que puede ser a favor del jugador, del crupier o un empate. El objetivo es obtener una mano lo más cercana posible a 9 puntos sumando el valor de las cartas. Aunque el Baccarat se popularizó en películas y es asociado con alta sociedad, en realidad es un juego accesible y emocionante para todo tipo de jugadores",
  };

  const reglas = {
    id: "anchor2",
    h2: "¿Cómo jugar Baccarat online? ",
    p:
      "Para jugar Baccarat Online en Codere, dirigite a la sección de casino y buscá Baccarat. Hacé una apuesta, recibí tus cartas, determina el ganador y cobrá" +
      " tus ganancias. ¡Buena suerte!",
    h3: "Reglas del juego ",

    div: {
      pL: "Las reglas básicas del juego de Baccarat son simples:",
      list: [
        "El juego utiliza seis o más barajas de cartas y dos manos se reparten: la del jugador y la del crupier.",
        "El objetivo es apostar por la mano que se acerque más a un total de 9 puntos.\n" +
          "Las cartas del dos al nueve tienen su valor nominal, mientras que las cartas con figuras (J, Q, K) y los 10 valen 0 puntos.\n",
        "Si la suma de las cartas es mayor a 9, solo se considera el dígito de las unidades. Por ejemplo, una mano con un 7 y un 8 sumaría 5 en lugar de 15.",
        'Si el jugador o el crupier tiene un total de 8 o 9 con las dos primeras cartas, se considera un "natural" y se gana automáticamente, a menos que ambas manos tengan el mismo total, lo que resultaría en un empate.',
        'Si ninguna mano tiene un "natural", se pueden repartir cartas adicionales según reglas específicas.',
        "El crupier toma decisiones según un conjunto predeterminado de reglas, sin intervención del jugador.",
      ],
    },
  };

  const secretos = {
    id: "anchor3",
    h2: "Juegos de Baccarat en Codere",
    p: "Algunos de los juegos de Baccarat que tenemos para vos, son:",
    bl1: {
      h3: "Baccarat Live ",
      p: "Baccarat Live es una versión del juego de Baccarat que se juega en línea con crupieres reales en tiempo real a través de una transmisión en vivo. Ofrece una experiencia auténtica de casino con interacción en vivo y resultados determinados por cartas físicas.",
    },
    bl2: {
      h3: "Peek Baccarat",
      p: "En Peek Baccarat la que el crupier puede ver las cartas antes de seguir repartiendo. Esto puede influir en las decisiones de reparto adicionales y añade más estrategia al juego.",
    },
    bl3: {
      h3: "Speed Baccarat",
      p: "Speed Baccarat es una versión del juego clásica diseñada para ofrecer una experiencia más rápida y dinámica. Aquí se reduce el tiempo entre las rondas de apuestas y la revelación de las cartas, lo que permite a los jugadores disfrutar de una acción continua y emocionante. ",
    },
    bl4: {
      h3: "No Commission Baccarat",
      p: "No Commission Baccarat es una versión del juego donde no se aplica comisión por apostar al banquero, lo que resulta más atractivo para los jugadores en comparación con el Baccarat tradicional. ",
    },
  };
  const estrategias = {
    id: "anchor4",
    h2: "Consejos para jugar",
    list: [
      "Gestioná tu dinero: establecé un presupuesto y apostá de forma responsable.",
      "Gestioná tu dinero: establecé un presupuesto y apostá de forma responsable.",
      "Considerá las probabilidades y elegí tus apuestas con cuidado.",
      "Mantené la calma: controla tus emociones y juega con serenidad.",
      "Probá con estrategias: experimentá con diferentes enfoques de juego para encontrar el que mejor se adapte a vos.",
      "Divertite: disfrutá del juego y no te obsesiones con ganar o perder.",
    ],
  };

  const preguntas = {
    id: "anchor5",
    h2: "Preguntas frecuentes",
    sub: [
      {
        h3: "¿Qué cartas usar para jugar al baccarat?",
        p: "Para jugar al Baccarat se utilizan cartas estándar de baraja francesa. Las cartas numéricas (2 al 9) conservan su valor nominal, mientras que las figuras (J, Q, K) y el 10 tienen un valor de 0 puntos. El As equivale a 1 punto.",
      },
      {
        h3: "¿Cuánto valen las cartas en Baccarat?",
        p: "En el Baccarat, las cartas tienen los siguientes valores: Las cartas numéricas (del 2 al 9) conservan su valor nominal. Las cartas con figuras (J, Q, K) y el 10 tienen un valor de 0 puntos. El As tiene un valor de 1 punto.",
      },
    ],
  };

  return (
    <div className="cas-seo">
      <Floating text="¡La emoción del azar!" juega={true} />
      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/casino/assets/Baccarat/${
            props.flag ? "M" : "D"
          }-Header.webp)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container style={{ color: "#fff" }}>
        <h1 className="header mt-4 mb-3">{top_par.h1}</h1>
        {top_par.p.map((par, k) => (
          <p key={k}>{par}</p>
        ))}

        {/* Table */}
        {!props.flag && <TableOfContents table={table_of_contents_list} />}

        <h2 id={como_juega.id} className="mt-4 mb-3">
          {como_juega.h2}
        </h2>
        <p>{como_juega.p}</p>

        {/* Games */}
        <LiveGamesDisplay live_games={live_games} device={props.flag} />

        <div id={reglas.id}>
          <h2 className="mt-4 mb-3">{reglas.h2}</h2>
          <p>{reglas.p}</p>
          <h3>{reglas.h3}</h3>
          <p>{reglas.div.pL}</p>
          <ol>
            {reglas.div.list.map((li, k) => (
              <li key={k}>{li}</li>
            ))}
          </ol>
        </div>
        <div id={secretos.id}>
          <h2 className="mt-4 mb-3">{secretos.h2}</h2>
          <p>{secretos.p}</p>
          <h3>{secretos.bl1.h3}</h3>
          <p>{secretos.bl1.p}</p>
          <h3>{secretos.bl2.h3}</h3>
          <p>{secretos.bl2.p}</p>
          <h3>{secretos.bl3.h3}</h3>
          <p>{secretos.bl3.p}</p>
          <h3>{secretos.bl4.h3}</h3>
          <p>{secretos.bl4.p}</p>
        </div>

        <div id={estrategias.id}>
          <h2 className="mt-4 mb-3">{estrategias.h2}</h2>
          <ul>
            {estrategias.list.map((li, k) => (
              <li key={k}>{li}</li>
            ))}
          </ul>
        </div>

        <div id={preguntas.id}>
          <h2 className="mt-4 mb-3">{preguntas.h2}</h2>

          <Accordion>
            {preguntas.sub.map((s, k) => (
              <Accordion.Item key={k} eventKey={k}>
                <Accordion.Header as={"h3"}>{s.h3}</Accordion.Header>
                <Accordion.Body as={"p"}>{s.p}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>

        <Button href={regis} className="cas-reg-btn" rel="nofollow">
          Registrate
        </Button>
      </Container>
    </div>
  );
}
