import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";
//DONE
export default function Formula(props) {
  useEffect(() => {
    let title = "Apuestas en Línea a la Fórmula 1 » Bono $200.000  | Codere®";
    let desc =
      "Te presentamos esta guía sobre la F1 🏁 para que disfrutes al 100% este hermoso deporte, lleno de adrenalina y vivi la experiencia Codere.";

    let can = "https://www.codere.bet.ar/cuotas-deportivas/apuestas-formula1";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Quién organiza  la Fórmula 1?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "La Fórmula 1 es organizada y gestionada por la Formula One Group, una entidad que pertenece a Liberty Media Corporation desde enero de 2017. Antes de Liberty Media, la Fórmula 1 estaba bajo la propiedad de la empresa británica de capital privado CVC Capital Partners. La Formula One Group es responsable de supervisar y gestionar todos los aspectos relacionados con la Fórmula 1, incluyendo la organización de los Grandes Premios, la comercialización de los derechos televisivos, la gestión de los derechos comerciales y la promoción del deporte a nivel mundial.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuál es el equipo con más victorias de la F1?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "El equipo con más victorias en la historia de la Fórmula 1 es la escudería Scuderia Ferrari. Ferrari es uno de los equipos más icónicos y exitosos en la historia de la F1, habiendo conseguido numerosos campeonatos de pilotos y constructores a lo largo de los años. La combinación de su legado, recursos financieros, tecnología innovadora y talento ha contribuido a su dominio en el deporte durante décadas.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/formula1/${
    props.flag ? "M" : "D"
  }-`;

  const top_par = {
    h1: "Guía de apuestas para los apasionados de la Fórmula 1 ",
    p: "En Codere te presentamos esta guía sobre la F1 para que disfrutes al 100% este hermoso deporte, lleno de adrenalina.",
  };

  const table_list = [
    {
      title: "La fórmula 1 y sus principales circuitos",
      id: "anchor1",
    },
    {
      title: "Tipos de apuestas",
      id: "anchor2",
    },
    {
      title: "Preguntas Frecuentes",
      id: "anchor3",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "La Fórmula 1 y sus principales circuitos  ",
    list: [
      {
        liTitle: "Circuito de Mónaco: ",
        liText:
          "famoso por su estrechez y curvas cerradas, se corre en las calles de Montecarlo, Mónaco, siendo uno de los más glamorosos de la Fórmula 1.",
      },
      {
        liTitle: "Circuito de Spa-Francorchamps: ",
        liText:
          "situado en Bélgica, es conocido por su clima impredecible, largas rectas y curvas rápidas y técnicas en el pintoresco entorno de las Ardenas.",
      },
      {
        liTitle: "Circuito de Silverstone: ",
        liText:
          ": uno de los circuitos más antiguos y emblemáticos en el Reino Unido, desafía a pilotos y equipos con sus curvas rápidas y rectas largas.",
      },
      {
        liTitle: "Circuito de Monza: ",
        liText:
          "ubicado en Italia, es famoso por sus altas velocidades con rectas largas y curvas rápidas, siendo uno de los más rápidos en el calendario de la Fórmula 1.",
      },
      {
        liTitle: "Circuito de Suzuka: ",
        liText:
          "en Japón, es reconocido por su diseño técnico con una mezcla de curvas rápidas y lentas, además de cambios de elevación desafiantes.",
      },
      {
        liTitle: "Circuito de Interlagos: ",
        liText:
          "situado en São Paulo, Brasil, se caracteriza por su diseño ondulado y desafiante que crea emocionantes carreras.",
      },
      {
        liTitle: "Circuito de Yas Marina:  ",
        liText:
          ": en Abu Dabi, destaca por su arquitectura moderna y su posición como anfitrión del Gran Premio de Abu Dabi, que suele cerrar la temporada de Fórmula ",
      },
    ],
    h3: "Top de pilotos:",
    pilotosPar:
      "Te dejamos una lista con algunos de los pilotos más prestigiosos en la historia de la Fórmula 1:",
    pilots: [
      {
        liTitle: "Lewis Hamilton (Mercedes): ",
        liText: "estrella indiscutible, múltiple campeón con Mercedes.",
      },
      {
        liTitle: "Max Verstappen (Red Bull Racing): ",
        liText: "joven talento, agresivo y desafiante en pista.",
      },
      {
        liTitle: "Valtteri Bottas (Alfa Romeo Racing): ",
        liText: "experimentado, excompañero de Hamilton en Mercedes.",
      },
      {
        liTitle: "Charles Leclerc (Ferrari): ",
        liText: "joven promesa, destacado piloto con Ferrari.",
      },
      {
        liTitle: "Daniel Ricciardo (McLaren): ",
        liText: "carismático y agresivo, busca el éxito con McLaren.",
      },
      {
        liTitle: "Sergio Pérez (Red Bull Racing):",
        liText:
          "hábil gestor de carreras, valioso compañero de equipo de Verstappen.",
      },
      {
        liTitle: "Lando Norris (McLaren): ",
        liText:
          "joven estrella en ascenso, impresiona con su velocidad y madurez.",
      },
      {
        liTitle: "Carlos Sainz Jr. (Ferrari): ",
        liText: "piloto talentoso, se une a Ferrari para buscar logros.",
      },
      {
        liTitle: "Fernando Alonso (Alpine): ",
        liText: "experimentado campeón, vuelve con Alpine para más desafíos.",
      },
      {
        liTitle: "George Russell (Mercedes): ",
        liText:
          "joven promesa, oportunidad con Mercedes para demostrar su talento.",
      },
    ],
    constructoras: [
      {
        h3: "Constructoras ",
        text: "Te presentamos un resumen de las principales constructoras y equipos más destacados en el campeonato mundial de Fórmula 1. Desde el dominio histórico de escuderías icónicas como Mercedes-AMG Petronas y Scuderia Ferrari, hasta la energía innovadora de Red Bull Racing y el resurgimiento de nombres legendarios como McLaren y Aston Martin, cada equipo aporta su legado y competitividad a la pista. Junto a ellos, figuran también equipos como Alpine, Alfa Romeo, AlphaTauri, Haas y Williams, cada uno con su propia historia y trayectoria en el deporte de las carreras a nivel mundial.",
      },
    ],
  };
  const tips = {
    id: "anchor2",
    h2: "Tipos de apuestas en F1 ",
    p: "El funcionamiento básico se asemeja al de las apuestas en otros deportes, pero con algunas particularidades propias de la F1 tales como: ",
    subtitles: [
      {
        h3: "Ganador de carrera  ",
        p: "Aquí podés predecir qué corredor se quedará con la victoria al final de la carrera. Es una apuesta directa y emocionante que te permite respaldar a tu piloto favorito. ",
      },
      {
        h3: "Terminará en el podio  ",
        p: "Con esta opción tenés la oportunidad de apostar por los tres pilotos que ocuparán las primeras plazas al final de la carrera. Es una opción más flexible que el ganador de la carrera y te permite cubrir diferentes escenarios.   ",
      },
      {
        h3: "Vuelta rápida de la carrera  ",
        p: "Apostá por el piloto que crees que dará la vuelta más rápida durante la carrera. Esta opción es ideal para aquellos que quieren agregar intensidad a cada vuelta. Es una de las apuestas más emocionantes que existen en esta competencia, es estrategia pura.",
      },
      {
        h3: "Cantidad de abandonos o la posición del piloto en un equipo determinado  ",
        p: "Con Codere y esta opción podés apostar por el número de pilotos que abandonarán la carrera o la posición específica en la que terminará un piloto dentro de un equipo determinado; esta te permite apostar en aspectos más específicos y detallados de la carrera.",
      },
      {
        h3: "Top 6  ",
        p:
          'El término "top 6" en la Fórmula 1 puede referirse a los seis primeros lugares en una carrera, los cuales son puntos de referencia ' +
          "significativos en la clasificación final de una carrera. Estos seis primeros puestos son generalmente ocupados por los pilotos más " +
          "competitivos y exitosos en la pista.\n",
        p2:
          "Por ejemplo, durante una carrera de Fórmula 1, los puntos son otorgados a los seis primeros pilotos en la siguiente escala: 1ro " +
          '(25 puntos), 2do (18 puntos), 3ro (15 puntos), 4to (12 puntos), 5to (10 puntos) y 6to (8 puntos). Por lo tanto, alcanzar el "top 6"' +
          "significa finalizar entre los seis primeros lugares en una carrera, lo que es considerado un logro significativo debido a la competitividad " +
          "y el nivel de habilidad de los pilotos en la parrilla de la Fórmula 1.\n",
      },
      {
        h3: "Acabará en los puntos  ",
        p: 'En la Fórmula 1, "acabar en los puntos" significa terminar la carrera entre los diez primeros clasificados. Esto es significativo porque los puntos se otorgan a los diez primeros pilotos que cruzan la línea de meta, de acuerdo con la siguiente escala de puntos:',
        list: [
          "25 puntos",
          "18 puntos",
          "15 puntos",
          "12 puntos",
          "10 puntos",
          "8 puntos",
          "6 puntos",
          "4 puntos",
          "2 puntos",
          "1 puntos",
        ],
        p2: "Los pilotos que finalizan fuera de los diez primeros no reciben puntos para el campeonato de pilotos ni para el campeonato de constructores. Por lo tanto, terminar en los puntos es un objetivo importante para los equipos y pilotos, ya que contribuye significativamente a su posición en el campeonato.",
      },
      {
        h3: "Escudería en conseguir la vuelta rápida ",
        p: [
          "La escudería en conseguir la vuelta rápida en la Fórmula 1 puede variar de una carrera a otra, ya que cada piloto y equipo tiene la oportunidad" +
            " de lograr la vuelta más rápida durante la carrera. En la Fórmula 1 moderna, se otorgan puntos adicionales al piloto y al equipo que registra la vuelta más rápida en la carrera, siempre y cuando el piloto termine entre los diez primeros clasificados.",
          "Por lo tanto, la escudería que consigue la vuelta rápida en una carrera específica puede ser cualquiera de las que participen en ese evento, dependiendo de la estrategia de carrera, las condiciones de pista y el rendimiento del coche y el piloto en ese día en particular.",
        ],
      },
      {
        h3: "Coche Ganador de la Carrera ",
        p: 'En la Fórmula 1, el término "coche ganador de la carrera" se refiere al vehículo que cruza la línea de meta primero al final de una competencia. Este automóvil es conducido por un piloto y ha completado todas las vueltas requeridas en el menor tiempo posible, superando a todos los demás competidores en la pista. El coche ganador representa la victoria en la carrera y es crucial para el éxito tanto del piloto como del equipo de la escudería a la que pertenece.',
      },
      {
        h3: "Nacionalidad del piloto ganador ",
        p: "La nacionalidad del piloto ganador en la Fórmula 1 puede variar en cada carrera, ya que los pilotos de diferentes nacionalidades compiten en el campeonato mundial. Los corredores pueden ser de países como Alemania, Reino Unido, Finlandia, Países Bajos, entre otros. Por lo tanto, en Codere podés seleccionar la nacionalidad del piloto que crees que ganará la carrera para realizar tus pronósticos.",
      },
      {
        h3: "Margen de victoria ",
        p: [
          'El "margen de victoria" en las apuestas deportivas de la Fórmula 1 se refiere a la diferencia de tiempo entre el piloto que cruza la línea de meta primero y el que lo hace en segundo lugar. Es una opción de apuesta que implica predecir cuánto tiempo pasará entre el ganador de la carrera y el segundo clasificado.',
          'Por ejemplo, si el margen de victoria del primer puesto es de "menos de 5 segundos", significa que el apostador está prediciendo que el ganador cruzará la línea de meta menos de 5 segundos antes que el segundo clasificado. Por otro lado, si el margen de victoria del que apostaste anteriormente es de "más de 10 segundos", significa que el ganador se espera que termine con más de 10 segundos de ventaja sobre el segundo clasificado.',
          "Es importante tener en cuenta que el margen de victoria puede variar según las condiciones de la carrera, el rendimiento de los coches y los pilotos, entre otros factores.",
        ],
      },
    ],
  };

  const preguntas = {
    id: "anchor4",
    h2: "Preguntas frecuentes ",
    inner: [
      {
        h3: "¿Quién organiza  la Fórmula 1? ",
        p: "La Fórmula 1 es organizada y gestionada por la Formula One Group, una entidad que pertenece a Liberty Media Corporation desde enero de 2017. Antes de Liberty Media, la Fórmula 1 estaba bajo la propiedad de la empresa británica de capital privado CVC Capital Partners. La Formula One Group es responsable de supervisar y gestionar todos los aspectos relacionados con la Fórmula 1, incluyendo la organización de los Grandes Premios, la comercialización de los derechos televisivos, la gestión de los derechos comerciales y la promoción del deporte a nivel mundial.",
      },
      {
        h3: "¿Cuál es el equipo con más victorias de la F1?  ",
        p: "El equipo con más victorias en la historia de la Fórmula 1 es la escudería Scuderia Ferrari. Ferrari es uno de los equipos más icónicos y exitosos en la historia de la F1, habiendo conseguido numerosos campeonatos de pilotos y constructores a lo largo de los años. La combinación de su legado, recursos financieros, tecnología innovadora y talento ha contribuido a su dominio en el deporte durante décadas.",
      },
    ],
  };

  return (
    <>
      <Floating text="Apuestas de Formula1" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/formula1/${
            props.flag ? "M" : "D"
          }-Header.webp)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event">
          <Container>
            <h1 className="subtitle">{top_par.h1}</h1>
            <p>{top_par.p}</p>
            {!props.flag && <TableOfContents table={table_list} />}

            <div className="mt-4" id={history.id}>
              <h2 className="subtitle">{history.h2}</h2>
              <ol>
                {history.list.map((item, i) => (
                  <li key={i} className={"mb-1"}>
                    <strong>{item.liTitle}</strong>
                    {item.liText}
                  </li>
                ))}
              </ol>
              <h3 className="subtitle">{history.h3}</h3>
              <p>{history.pilotosPar}</p>
              <ol>
                {history.pilots.map((item, i) => (
                  <li key={i} className={"mb-1"}>
                    <strong>{item.liTitle}</strong>
                    {item.liText}
                  </li>
                ))}
              </ol>

              {history.constructoras.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.text}</p>
                </div>
              ))}
            </div>
            <div className="mt-4" id={tips.id}>
              <h2 className="subtitle">{tips.h2}</h2>
              <p>{tips.p}</p>
              {tips.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  {Array.isArray(inn.p) ? (
                    inn.p.map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))
                  ) : (
                    <p>{inn.p}</p>
                  )}
                  {inn.list && (
                    <ul className={"ml-1"}>
                      {inn.list.map((li, index) => (
                        <li key={index}>{li}</li>
                      ))}
                    </ul>
                  )}
                  {inn.p2 && <p>{inn.p2}</p>}
                </div>
              ))}
            </div>

            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>{inn.p}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + "Formula1-2.jpg"} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
