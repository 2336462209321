import gameCSS from "../CSS/games.module.css";
import { Arrowrightw } from "../icons";
import { NavLink } from "react-bootstrap";
import { Bounce } from "react-awesome-reveal";
import { replaceSpanishCharacters } from "./Games";
import { SPEEDSIZE_PREFIX } from "../App";

function BottomGame({ game }) {
  const imgPrefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/assets/splash-games/desktop`;
  const gamePrefix =
    "https://m.caba.codere.bet.ar/deportes/#/casino/CasinoPage?lobby=ArgentinaCaba&playgame=";

  return (
    <NavLink
      href={`${gamePrefix}${replaceSpanishCharacters(game.name)}`}
      rel="nofollow"
    >
      <div className={gameCSS.bottom_card}>
        <div className={gameCSS.bottom_box}>
          <div className={gameCSS.game}>
            <div className={game.id === 1 ? gameCSS.shiny : ""}>
              <img className={'codereGameImg'}
                src={`${imgPrefix}/${replaceSpanishCharacters(game.name)
                  .toLowerCase()
                  .replaceAll(/ /g, "-")}.webp`}
                alt={game.name}
              />
            </div>
          </div>

          <div className={`${gameCSS.logo} bottom-logo-${game.id + 1}`}>
            <Bounce duration={1500} right>
              <img className={'codereGameImg'}
                src={`${imgPrefix}/${replaceSpanishCharacters(game.name)
                  .toLowerCase()
                  .replaceAll(/ /g, "-")}-logo.webp`}
                alt={`${game.name}-logo`}
              />
            </Bounce>
          </div>
        </div>
        <div className={gameCSS.bottom_content}>
          <span
            className={`${gameCSS.regis} d-flex m-auto justify-content-center rounded-pill`}
          >
            Jugá Ahora
            <div id={gameCSS.game_svg}>
              <Arrowrightw />
            </div>
          </span>
        </div>
        <div className={gameCSS.bottom_game_title}>
          <h3>{game.name.split(" ").slice(1).join(" ")}</h3>
        </div>
      </div>
    </NavLink>
  );
}

export default BottomGame;
