import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";
//DONE
export default function NFL(props) {
  useEffect(() => {
    let title = "Apuestas de Futbol Americano » Bono $200.000  | Codere®";
    let desc =
      "Acá podrás encontrar las diferentes opciones de apuestas para la NFL 🏈 así como también, resolver dudas sobre este juego muy popular en Estados Unidos.";

    let can =
      "https://www.codere.bet.ar/cuotas-deportivas/apuestas-nfl-futbol-americano";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Quiénes son los equipos favoritos en la NFL?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Los favoritos en la NFL varían según la temporada y circunstancias actuales, pero equipos como los Kansas City Chiefs, Tampa Bay Buccaneers, Green Bay Packers, Buffalo Bills y Los Angeles Rams tienen sólidas bases de seguidores debido a su historial de éxito y rendimiento.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuánto dinero obtiene el ganador del Super Bowl?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "El monto que recibe el equipo ganador del Super Bowl puede variar de año en año debido a diversos factores, como los acuerdos de patrocinio, las ventas de entradas y la distribución de los ingresos de la NFL. Sin embargo, por lo general, el equipo ganador del Super Bowl recibe una cantidad significativa de dinero como premio.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/nfl/${
    props.flag ? "M" : "D"
  }-superbowl-`;
  const jpg = ".jpg";

  const top_par = {
    h1: "Apuestas NFL: llevá la Competencia a otro nivel",
    p: "Acá podrás encontrar las diferentes opciones de apuestas para el fútbol americano, así como también, resolver dudas sobre este juego muy popular en Estados Unidos.  ",
  };

  const table_list = [
    {
      title: "Conoce sobre de la National Football League (NFL) ",
      id: "anchor1",
    },
    {
      title: "Sistema de conferencias de la NFL ",
      id: "anchor2",
    },
    {
      title: "El Super Bowl",
      id: "anchor3",
    },
    {
      title: "Tipos de apuestas en la NFL ",
      id: "anchor4",
    },
    {
      title: "Preguntas frecuentes ",
      id: "anchor5",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "Conocé sobre de la National Football League (NFL) ",
    p: "La NFL es la liga profesional de fútbol americano de los Estados Unidos. Su historia se inicia en 1920, cuando se creó la Asociación Americana de Fútbol Profesional, que en 1922 cambió su nombre a NFL. Al finalizar la temporada regular, siete equipos de cada conferencia avanzan a los playoffs, que son una serie de eliminatorias para determinar los campeones de cada conferencia, y luego enfrentarse en el partido más importante del año: el Super Bowl. ",

    subtitles: [
      {
        h3: "Sistema de conferencias de la NFL ",
        p: "La NFL se divide en dos conferencias principales: la Conferencia Nacional (NFC) y la Conferencia Americana (AFC). Cada conferencia está compuesta por cuatro divisiones: Norte, Sur, Este y Oeste. Cada división tiene cuatro equipos. Durante la temporada regular, los equipos compiten dentro de sus respectivas divisiones y conferencias. Al final de la temporada regular, los mejores equipos de cada división, así como algunos equipos con los mejores registros en general, avanzan a los playoffs, donde compiten para llegar al Super Bowl y convertirse en campeones de la NFL.  ",
      },
      {
        h3: "Principales equipos en la actualidad",
        p: "En la actualidad, los principales equipos de la NFL son los New England Patriots, Dallas Cowboys, Green Bay Packers, Pittsburgh Steelers y San Francisco 49ers.",
      },
    ],
  };

  const tips = {
    id: "anchor2",
    h2: "El Super Bowl  ",
    p: 'El "Super Bowl", también conocido como "Súper Tazón", es el partido final del campeonato de la National Football League (NFL) de Estados Unidos, y es uno de los eventos deportivos más relevantes a nivel mundial.',
    subtitles: [
      {
        h3: "El medio tiempo ",
        p: 'El "medio tiempo" en la NFL es un término que se refiere al descanso extendido que ocurre a la mitad de un partido de fútbol americano, incluido el Super Bowl. Durante este intervalo, se llevan a cabo varios eventos de entretenimiento, como presentaciones musicales, espectáculos de baile, y a menudo se transmiten anuncios publicitarios de alto perfil. Es un momento muy esperado tanto por los fanáticos del fútbol americano como por los espectadores casuales, debido a la calidad de las actuaciones y a la extravagancia de la producción.',
      },
    ],
  };

  const como_hacer = {
    id: "anchor3",
    h2: "Tipos de Apuestas    ",
    p: "Acá podés jugar por el que gana el partido, el que se lleva cada conferencia, el crack del equipo, la defensa más firme e incluso el DT que se destacó en la temporada regular. Cada una de estas alternativas tiene su manera de hacerse, así que asegurate de entender bien las reglas antes de poner tu apuesta. También, podrás usar la función “Crea Tu Apuesta”, que te permite realizar múltiples combinaciones en tus partidos favoritos con facilidad, eligiendo múltiples mercados en un mismo evento y apostando según tus preferencias.",
    subtitles: [
      {
        h3: "Super Bowl - Ganador ",
        p: "Solo dos equipos llegan a la final de la NFL. Estos conjuntos tienen varias cuotas para aumentar sus posibilidades de ganar. Además, durante el transcurso del juego, tenés la opción de apostar por tu favorito.",
      },
      {
        h3: "Ganador Conferencia AFC (Play-Off Incluido) ",
        p: "La apuesta de ganador de la “Conferencia AFC” en la NFL se refiere a apostar por el equipo que se espera que gane la Conferencia Americana de Fútbol (AFC) en la National Football League (NFL) que se compone de 16 equipos. ",
      },
      {
        h3: "Ganador Conferencia NFC (Play-Off Incluido) ",
        p: "La apuesta de ganador de la conferencia NFC en la NFL es una apuesta en la que se pronostica qué equipo ganará la Conferencia Nacional de Fútbol Americano (NFC) y avanzará al Super Bowl. Esta también se compone de 16 equipos.  ",
      },
      {
        h3: "MVP temporada regular ",
        p: "Esta opción es para el jugador más consistente durante toda la temporada del fútbol americano. Podés encontrarla en la sección de “cuotas especiales” o “anticipadas NFL” ",
      },
      {
        h3: "Jugador defensivo del año ",
        p: "Esta opción es para el jugador defensivo más constante en el fútbol americano durante todo el año. Y también podrás encontrarla en la sección de cuotas especiales” o “anticipadas NFL” ",
      },
      {
        h3: "Entrenador del año ",
        p: "Siempre hay un premio para el estratega más destacado de la temporada regular del campeonato. Los jugadores pueden pronosticarlo en la opción de cuotas especiales. ",
      },
    ],
  };

  const preguntas = {
    id: "anchor4",
    h2: "Preguntas frecuentes ",
    inner: [
      {
        h3: "¿Quiénes son los equipos favoritos en la NFL?  ",
        p: "Los favoritos en la NFL varían según la temporada y circunstancias actuales, pero equipos como los Kansas City Chiefs, Tampa Bay Buccaneers, Green Bay Packers, Buffalo Bills y Los Angeles Rams tienen sólidas bases de seguidores debido a su historial de éxito y rendimiento.",
      },
      {
        h3: "¿Cuánto dinero obtiene el ganador del Super Bowl?  ",
        p: "El monto que recibe el equipo ganador del Super Bowl puede variar de año en año debido a diversos factores, como los acuerdos de patrocinio, las ventas de entradas y la distribución de los ingresos de la NFL. Sin embargo, por lo general, el equipo ganador del Super Bowl recibe una cantidad significativa de dinero como premio.",
      },
    ],
  };

  return (
    <>
      <Floating text="Apuestas de NFL" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/cuotas-deportivas/nfl/${
            props.flag ? "M" : "D"
          }-Header.jpg)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event">
          <Container>
            <h1 className="header subtitle">{top_par.h1}</h1>
            <p>{top_par.p}</p>
            {!props.flag ? <TableOfContents table={table_list} /> : <></>}

            <div id={history.id}>
              <h2 className="subtitle">{history.h2}</h2>
            </div>
            <p>{history.p}</p>

            {history.subtitles.map((inn, k) => (
              <div key={k}>
                <h3 className="subtitle">{inn.h3}</h3>
                <p>{inn.p}</p>
              </div>
            ))}

            <div className="mt-4" id={tips.id}>
              <h2 className="subtitle">{tips.h2}</h2>
              <p>{tips.p}</p>
              {tips.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.p}</p>
                </div>
              ))}
            </div>
            <div className="mt-5" id={como_hacer.id}>
              <h2 className="subtitle">{como_hacer.h2}</h2>
              <p>{como_hacer.p}</p>
              {como_hacer.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3 className="subtitle">{inn.h3}</h3>
                  <p>{inn.p}</p>
                </div>
              ))}
            </div>
            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>{inn.p}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + "1" + jpg} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
