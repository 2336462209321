import { Button, Container, Card, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import Floating from "../Floating";
import { useEffect } from "react";
import { CodereHelmet } from "../../../App";
import { SPEEDSIZE_PREFIX } from "../../../App";

export default function EventosDeportivos(props) {
  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/assets/seo/sport/`;

  useEffect(() => {
    const title = "Los Mejores Eventos Deportivos » Bono 200.000 | Codere®";
    const desc =
      "Explorá aquí las guías completas para apostar en línea en tus eventos deportivos favoritos 🏇. Viví la adrenalina de cada apuesta con Codere.";

    let can = "https://www.codere.bet.ar/eventos-deportivos";

    CodereHelmet(title, desc, can, []);
  }, []);

  const data = {
    top_games: [
      {
        img: "balenco-promo",
        url: "/eventos-deportivos/apuestas-mundial-baloncesto",
        h2: "Apuesta para el Mundial de Basketball",
        game: "Basketball",
      },
      {
        img: "f1-promo",
        url: "/eventos-deportivos/apuestas-grand-prix",
        h2: "Apuesta para el Grand Prix de F1",
        game: "Formula1",
      },
      {
        img: "soccer-promo",
        url: "/eventos-deportivos/apuestas-mundial-fifa",
        h2: "Apuesta para el Mundial de Fútbol",
        game: "Mundial",
      },
      {
        img: "banner-eventos-mlb",
        url: "/eventos-deportivos/apuestas-serie-mundial",
        h2: "Baseball",
        game: "Baseball",
      },
      {
        img: "banner-eventos-superbowl",
        url: "/eventos-deportivos/apuestas-superbowl",
        h2: "Super Bowl",
        game: "SuperBowl",
      },
    ],
  };

  const cardGenerator = (g, i) => {
    return (
      <Col md={4} xs={6} key={i}>
        <Card className={`eventos_card `}>
          <Link style={{ textDecoration: "unset" }} to={g.url}>
            <Card.Header as={"h2"}>{!props.flag ? g.h2 : g.game}</Card.Header>
          </Link>
          <Card.Body>
            <LazyLoadImage src={prefix + g.img + ".webp"} />
          </Card.Body>
          <Card.Footer
            className={`ps-${props.flag ? "1" : "3"} pe-${
              props.flag ? "1" : "3"
            }`}
          >
            <Link rel="nofollow" to={g.url}>
              <Button as="span" className="w-100 fw-bold">
                VER INFORMACIÓN
              </Button>
            </Link>
          </Card.Footer>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div id="eventos">
        <Floating text="Deportes En Vivo" />
        <div
          className="top-bg-seo"
          style={{
            backgroundImage: `url(${SPEEDSIZE_PREFIX}${prefix}general-${
              !props.flag ? "desktop" : "mobile"
            }.jpg)`,
            backgroundSize: "cover",
          }}
        ></div>

        <h1 style={{ color: "#fff", textAlign: "center", padding: "1.5rem" }}>
          Los mejores Eventos Deportivos estan en Codere
        </h1>
        <Container className="eventos-container">
          <Row>
            {data.top_games.map((g, i) => {
              return cardGenerator(g, i);
            })}
          </Row>
        </Container>
      </div>
    </>
  );
}
