import { Route, Routes, useLocation } from "react-router-dom";
import Inicio from "../Components/Inicio";
import Basketball from "../Components/seo/sports/events/Basketball";
import Mundeal from "../Components/seo/sports/events/Mundeal";
import Formula from "../Components/seo/sports/events/Formula";
import Baseball from "../Components/seo/sports/events/Baseball";
import SuperBowl from "../Components/seo/sports/events/SuperBowl";
import EventosDeportivos from "../Components/seo/sports/EventosDeportivos";
import Casino from "../Components/seo/casino/Casino";
import Slots from "../Components/seo/casino/Slots";
import CasinoLive from "../Components/seo/casino/CasinoLive";
import Blackjack from "../Components/seo/casino/Blackjack";
import Baccarat from "../Components/seo/casino/Baccarat";
import Ruleta from "../Components/seo/casino/Ruleta";
import Navigations from "../Components/seo/Navigations";
import { useEffect, useState } from "react";
import CuotasDeportivos from "../Components/seo/sports/CuotasDeportivas";
import Baloncesto from "../Components/seo/sports/cuotasDeportivas/Baloncesto";
import Ciclismo from "../Components/seo/sports/cuotasDeportivas/Ciclismo";
import Formula1 from "../Components/seo/sports/cuotasDeportivas/Formula";
import Futbol from "../Components/seo/sports/cuotasDeportivas/Futbol";
import MLB from "../Components/seo/sports/cuotasDeportivas/MLB";
import NFL from "../Components/seo/sports/cuotasDeportivas/NFL";
import Tenis from "../Components/seo/sports/cuotasDeportivas/Tenis";
import UFC from "../Components/seo/sports/cuotasDeportivas/UFC";

export default function Routing({ device }) {
  const location = useLocation();
  const [activated, setActivated] = useState(0);
  const [isCasinoPage, setCasinoPage] = useState(
    location.pathname.includes("casino")
  );

  useEffect(() => {
    setCasinoPage(location.pathname.includes("casino"));
    changeFavicon();
    setActivated(activeHandler);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  function changeFavicon() {
    let fav = document.querySelector("link[rel='icon'");
    fav.parentNode.removeChild(fav);

    //
    const newFav = document.createElement("link");
    newFav.rel = "icon";
    newFav.href = `https://www.codere.bet.ar/assets/icons/favicon-${
      location.pathname.includes("casino") ? "casino" : "deportes"
    }.ico`;
    document.head.appendChild(newFav);
  }

  const activeHandler = () => {
    switch (location.pathname) {
      default:
        return 0;
      case "/casino":
      case "/eventos-deportivos/apuestas-mundial-baloncesto":
      case "/cuotas-deportivas/apuestas-baloncesto":
        return 1;
      case "/casino/casino-en-vivo":
      case "/eventos-deportivos/apuestas-grand-prix":
      case "/cuotas-deportivas/apuestas-formula1":
        return 2;
      case "/casino/slots":
      case "/eventos-deportivos/apuestas-mundial-fifa":
      case "/cuotas-deportivas/apuestas-futbol":
        return 3;
      case "/casino/blackjack":
      case "/eventos-deportivos/apuestas-serie-mundial":
      case "/cuotas-deportivas/apuestas-ciclismo":
        return 4;
      case "/casino/baccarat":
      case "/eventos-deportivos/apuestas-superbowl":
      case "/cuotas-deportivas/apuestas-nfl-futbol-americano":
        return 5;
      case "/casino/ruleta":
      case "/cuotas-deportivas/apuestas-ufc":
        return 6;
      case "/cuotas-deportivas/apuestas-tenis":
        return 7;
      case "/cuotas-deportivas/apuestas-beisbol-grandes-ligas":
        return 8;
    }
  };
  return (
    <>
      {location.pathname.includes("eventos") ||
      location.pathname.includes("casino") ||
      location.pathname.includes("cuotas") ? (
        <Navigations
          index={activated}
          type={
            location.pathname.includes("eventos") && !location.hash
              ? 0
              : location.pathname.includes("cuotas")
              ? 2
              : 1
          }
        />
      ) : (
        <></>
      )}

      <Routes location={location}>
        <Route path="/" element={<Inicio  device={device} />} />
        <Route exact path="eventos-deportivos">
          <Route exact path="" element={<EventosDeportivos flag={device} />} />
          <Route
            exact
            path="apuestas-mundial-baloncesto"
            element={<Basketball flag={device} />}
          />
          <Route
            exact
            path="apuestas-grand-prix"
            element={<Formula flag={device} />}
          />
          <Route
            exact
            path="apuestas-mundial-fifa"
            element={<Mundeal flag={device} />}
          />
          <Route
            exact
            path="apuestas-serie-mundial"
            element={<Baseball flag={device} />}
          />
          <Route
            exact
            path="apuestas-superbowl"
            element={<SuperBowl flag={device} />}
          />
        </Route>
        <Route exact path="cuotas-deportivas">
          <Route exact path="" element={<CuotasDeportivos flag={device} />} />
          <Route
            exact
            path="apuestas-baloncesto"
            element={<Baloncesto flag={device} />}
          />
          <Route
            exact
            path="apuestas-ciclismo"
            element={<Ciclismo flag={device} />}
          />
          <Route
            exact
            path="apuestas-formula1"
            element={<Formula1 flag={device} />}
          />
          <Route
            exact
            path="apuestas-futbol"
            element={<Futbol flag={device} />}
          />
          <Route
            exact
            path="apuestas-beisbol-grandes-ligas"
            element={<MLB flag={device} />}
          />
          <Route
            exact
            path="apuestas-nfl-futbol-americano"
            element={<NFL flag={device} />}
          />
          <Route
            exact
            path="apuestas-tenis"
            element={<Tenis flag={device} />}
          />
          <Route exact path="apuestas-ufc" element={<UFC flag={device} />} />
        </Route>
        <Route exact path="casino">
          <Route exact path="" element={<Casino flag={device} />} />
          <Route
            exact
            path="casino-en-vivo"
            element={<CasinoLive flag={device} />}
          />
          <Route exact path="slots" element={<Slots flag={device} />} />
          <Route exact path="blackjack" element={<Blackjack flag={device} />} />
          <Route exact path="baccarat" element={<Baccarat flag={device} />} />
          <Route exact path="ruleta" element={<Ruleta flag={device} />} />
        </Route>
      </Routes>
    </>
  );
}
