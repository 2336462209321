import { Zoom } from "react-awesome-reveal";
import { Accordion, Table } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

var questions = [
  "¿Cómo puedo registrarme para realizar apuestas deportivas?",
  "¿Dónde puedo mirar estadísticas y pronósticos en la casa de apuestas Codere?",
  "¿Cuáles son los tipos de apuestas deportivas en fútbol?",
  "¿Qué es una Freebet y cómo lo puedo utilizar?",
  "¿Qué es una apuesta combinada?",
];
var answers = [
  "Para registrarte en Codere y poder realizar apuestas deportivas primero tenés que crearte una cuenta. Hacé clic en la pestaña “Registrarse” y completá tus datos personales para crear tu perfil. Tené a mano tu DNI ya que te solicitaremos el número de trámite. Recordá que solo podrás apostar cuando te encuentres ubicado dentro de la Ciudad Autónoma de Buenos Aires. Aprovechá nuestro bono de bienvenida y usalo en apuestas deportivas. Luego de crear tu cuenta no olvides realizar la verificación documental.",
  "Antes de realizar tus apuestas deportivas en Codere, tenés la opción de ayudarte con nuestras estadísticas o pronósticos. Hacé clic en el evento deportivo sobre el que quieras apostar y entrá a uno de los partidos. Una vez dentro, verás que se abrirá un panel lateral con todo tipo de ayudas. Contarás con información sobre ambos equipos, el estadio donde se juega, horas que quedan para el inicio, árbitro, goles marcados esta temporada, máximos goleadores, etc. Además, habrá otras pestañas en las que podrás ver los enfrentamientos anteriores entre ambos, la probabilidad de victoria de cada uno, la ubicación en la tabla de posiciones y las alineaciones probables en el caso del fútbol.",
  "Existen infinidad de mercados para realizar apuestas deportivas. Pero por encima del resto hay unos cuantos que son los más elegidos por parte de los usuarios de Codere en otros países de América y Europa. Entre ellos destacan: Ganador del partido, Más o menos goles, Primer equipo en hacer un gol, Goleadores, Hándicap e incluso apuestas anticipadas para apostar al ganador de una competición.",
  "Una Freebet es una apuesta gratis que Codere te brinda. No ponés en riesgo tu dinero y podés obtener ganancias si ganás la apuesta. Existen freebets de diferentes montos que son entregadas dependiendo de la promoción vigente. En Codere, las Freebet de bono de bienvenida son otorgadas cuando un usuario se registra y hace su primer depósito. Todas las freebets pueden ser usadas en cualquier tipo de apuesta deportiva, siempre y cuando cumpla con los términos y condiciones. Al hacer tu apuesta, al lado del botón de hacer apuesta, estará un cuadro que dirá Freebet. Al hacer clic ahí, se muestra la cantidad de Freebet que tenés disponible y podrás realizar tu apuesta con Freebet.",
  "En Codere, al hacer una apuesta deportiva con varias selecciones de eventos o incidencias dentro del mismo evento, se le llama apuesta combinada. Cuantos más eventos incluyas en la apuesta, mayor es la ganancia posible que va aumentando exponencialmente. Para ganar una apuesta combinada de todos los juegos seleccionados debés acertar la totalidad de tus predicciones dentro de la apuesta. Atentos que en Codere a veces aumentamos la cantidad de ganancia por las líneas dentro de una apuesta combinada, es decir damos un porcentaje extra por selecciones adicionales.",
];

function Content() {
  return (
    <div className="content-container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-4 left-col">
          <Zoom triggerOnce>
            <h1>
              ¡Bienvenido a la mejor casa de apuestas en línea de Argentina!
            </h1>
            <p>
              En Codere, sumergite en la pasión por el deporte y aprovechá tus
              conocimientos para ganar dinero real con apuestas en línea, en un
              entorno seguro y legal. Somos la casa de apuestas oficial de River
              Plate y Real Madrid, y la opción número uno para apostar en todo
              el país. Disfrutá de apuestas deportivas en tus eventos favoritos,
              ya sea antes o durante los encuentros. Recordá que solo podrás
              realizar apuestas si te encontrás dentro de la Ciudad Autónoma de
              Buenos Aires o Mendoza. Preparate para vivir momentos emocionantes
              mientras celebrás cada gol y victoria.
            </p>
            <p>
              Divertite con nuestra amplia oferta en el mundo de las apuestas en
              línea, diseñada especialmente para aquellos que buscan vivir la
              emoción al máximo. Apostá en tus deportes favoritos, desde fútbol
              hasta NBA, tenis, NFL, MLB ¡y mucho más!{" "}
            </p>

            <h2>¿Querés aprender a hacer apuestas online con Codere?</h2>
            <p>
              ¡Las apuestas deportivas son una aventura emocionante! Con un poco
              de conocimiento e intuición, podés diseñar estrategias para
              maximizar tus ganancias. En Codere, ofrecemos una amplia variedad
              de opciones de apuestas en línea, desde simples hasta múltiples y
              combinadas, y muchas más.
            </p>
            <h3>Conoce qué es una apuesta combinada</h3>
            <p>
              Se trata de apostar por varios partidos en conjunto con la función
              de Crea tu Apuesta de Codere, prediciendo ganadores o empates. Es
              una práctica muy popular que puede llevarte a grandes ganancias.
              Cada estrategia de apuestas online es personalizada, adaptada por
              cada jugador.
            </p>
            <p>
              Para que tu experiencia sea única y emocionante, hemos preparado
              algunos consejos de apuestas deportivas que te ayudarán a aprender
              cómo ganar dinero mientras apostás y jugás como un profesional.
              Dejá que tu conocimiento se convierta en ganancias con Codere.
              ¡Descubrí cómo apostar con inteligencia y disfrutá al máximo del
              mundo de las apuestas deportivas!
            </p>
            <h3>Usa la función de “Crea tu apuesta”</h3>
            <p>
              "Crea tu apuesta", te permite combinar partidos de una manera más
              sencilla y adaptada a tus estrategias. Esta función te permite
              diseñar y realizar tu propia apuesta, y está disponible en los
              mercados previos al partido de fútbol.
            </p>
          </Zoom>
        </div>
        <div className="col-12 col-sm-4 middle-col">
          <Zoom triggerOnce>
            <p>
              Tené en cuenta que NO está disponible para apuestas en vivo. Es
              importante que todas las apuestas que desees realizar y todas las
              selecciones que hagas sean de un solo evento, ya que no se pueden
              combinar con otros eventos. "Crea tu apuesta" debe contener al
              menos 2 selecciones.
            </p>
            <p>
              <b>Algunos tips:</b> Todas las selecciones dentro de Crea tu
              Apuesta, deben ser ganadoras para obtener ganancias. Si una
              selección dentro de Crea tu Apuesta es nula, entonces el ticket
              será nulo. Además, se aplican todas las demás reglas regulares de
              apuestas. Seguinos de cerca en nuestras redes sociales, donde
              realizaremos análisis de los eventos y cuotas mejoradas para cada
              encuentro. Además, si sos fanático de River Plate, podrás
              disfrutar de nuestros rankings y ganar entradas para los partidos
              locales, viviendo así una #ExperienciaCodere única en el estadio
              Más Monumental.
            </p>
            <h3>Entender a los equipos </h3>
            <p>
              ¿Sos de los que piensan que ganar en las apuestas deportivas en
              línea es casi imposible? ¡Eso está a punto de cambiar! Descubrí
              que ser un ganador no es tan complicado como parece. Comenzamos
              revelando la primera verdad para tener éxito en las apuestas
              deportivas: el conocimiento es poder. Profundizá en el
              conocimiento de los equipos, los jugadores, los momentos claves y
              todo lo relacionado con tus deportes favoritos, esto te brindará
              una gran ventaja para aumentar tus posibilidades de ganar.
            </p>
            <p>
              Acá no se trata solo de conocer la historia de los equipos y
              jugadores, sino también de comprender su situación actual y su día
              a día. Para obtener ganancias consistentes en las apuestas
              deportivas, es fundamental “hacer la tarea”: conocer los equipos,
              los jugadores, cómo llega un equipo a un partido, qué jugadores
              están suspendidos, cuáles están lesionados, etc. Para triunfar en
              el mundo de las apuestas deportivas, es necesario convertirse en
              el director técnico de los equipos y jugadores con los que vas a
              desarrollar tu estrategia. Toda esta información te permitirá
              realizar apuestas precisas.
            </p>
            <h3>Conocer tus probabilidades</h3>
            <p>
              Por supuesto, es crucial tener un plan. En él, establecemos
              ciertos parámetros que definirán nuestro estilo para apostar: es
              fundamental determinar la cantidad de dinero a jugar y cómo
              distribuirlo entre las apuestas que nos interesan. Cada
              campeonato, equipo y jugador ofrece probabilidades diferentes, por
              lo que hay que estimar cuáles son y con qué jugaremos.
            </p>

            <h3>Aprender las estrategias </h3>
            <p>
              Cada victoria y cada tropiezo son fundamentales para desarrollar
              tu identidad como jugador, definiendo tus estrategias y los
              mercados en los que invertirás tu dinero. Aprender las estrategias
              de apuestas deportivas y casino online es esencial para apostar
              con éxito y buscar ganancias inmediatas.
            </p>
          </Zoom>
        </div>
        <div className="col-12 col-sm-4 right-col">
          <Zoom triggerOnce>
            <h3>Tips para apostar responsablemente </h3>
            <div>
              <p>
                Las apuestas deportivas requieren atención y cuidado, por lo que
                es importante apostar de manera responsable. Acá te ofrecemos
                algunos consejos:
              </p>
              <ul>
                <li>
                  Apostá siempre de manera consciente y dentro de tus
                  posibilidades. Este emocionante mundo de las apuestas requiere
                  una gestión adecuada de las emociones, adaptándolas a tu
                  estrategia.
                </li>
                <li>
                  Es esencial establecer límites y respetarlos. La
                  administración de tu presupuesto diario tiene un papel clave
                  en tu éxito en las apuestas deportivas y casino online.
                </li>
                <li>
                  Una sugerencia útil es distribuir tu presupuesto por días,
                  partidos o campeonatos. Queremos que disfrutes al máximo y nos
                  visites cuantas veces desees, por eso repartir tu presupuesto
                  te asegurará una experiencia duradera.
                </li>
                <li>
                  Muchos profesionales consideran el dinero apostado como dinero
                  ya gastado. Si ganás la apuesta y obtenés ganancias, ¡celebrá!
                  Y si la apuesta no sale como esperabas, recordá que ya habías
                  despedido ese monto y será más fácil asimilarlo.
                </li>
                <li>
                  Evitá apostar para recuperar pérdidas. Lo que se perdió, ya es
                  parte del pasado. Concentrate en las oportunidades futuras y
                  apostá siempre con la vista puesta hacia adelante.
                </li>
                <li>
                  Jugá siempre donde te sientas cómodo. Conocer bien el
                  territorio en el que invertís es esencial para tener éxito en
                  las apuestas deportivas.
                </li>
                <li>
                  No inviertas en competiciones que desconocés por completo. Es
                  más probable que tomes decisiones acertadas cuando te
                  concentres en lo que conocés. Recordá que jugar sin
                  conocimiento previo siempre es más complicado.
                </li>
                <li>
                  Tené presente que estamos apostando para aumentar nuestras
                  probabilidades. Seguí una estrategia que te permita
                  incrementar tus posibilidades de éxito en cada apuesta.
                </li>
              </ul>
            </div>
            <h3></h3>
            <p></p>
            <h2>Casino Online en Argentina</h2>
            <p>
              En Codere, ofrecemos mucho más que apuestas deportivas. Descubrí
              nuestro <Link to="/casino">casino online</Link> con una amplia
              variedad de juegos, incluyendo salas de bingo exclusivas y casinos
              en vivo con crupieres reales. Disfrutá de los mejores juegos de
              casino desde tu computadora o dispositivo móvil.
            </p>

            <h3>Casino en Vivo con los mejores juegos</h3>
            <p>
              En nuestro <Link to="/casino/casino-en-vivo">casino en vivo</Link>
              , encontrarás mesas y máquinas de todo tipo, incluyendo{" "}
              <Link to="/casino/blackjack"> BlackJack</Link>, Póker,{" "}
              <Link to="/casino/ruleta">Ruleta</Link>,{" "}
              <Link to="/casino/slots">tragamonedas</Link> y Video Bingo. Elegí
              tu juego favorito y disfrutá de la seguridad y tranquilidad que
              solo Codere puede ofrecerte. Estamos comprometidos en brindarte
              una experiencia de casino excepcional, con sorpresas, promociones
              y bonos constantes para nuestros jugadores.
            </p>
          </Zoom>
        </div>
      </div>
      <Table className="mt-3 mb-3" striped bordered hover variant="dark">
        <tbody>
          <tr>
            <td>⚽ Apuestas Deportivas</td>
            <td>Apuestas fútbol, Apuestas box, Apuestas baloncesto y más</td>
          </tr>
          <tr>
            <td>🎲 Licencia</td>
            <td>
              LOTBA S.E. mediante DI-2021-238-GCABA-LOTBA y
              DI-2023-359-GCABA-LOTBA.
            </td>
          </tr>
          <tr>
            <td>🎲 Licencia</td>
            <td>
              Licencia en la Provincia de Mendoza mediante Resolución N°
              192/2023
            </td>
          </tr>
          <tr>
            <td>✔️ Moneda</td>
            <td>ARS</td>
          </tr>
        </tbody>
      </Table>

      <section id="acc-section" className="mt-4">
        <h2 style={{ color: "#79c000" }}>
          Preguntas frecuentes sobre Apuestas deportivas en Argentina
        </h2>
        <Accordion
          className="content-accordion"
          defaultActiveKey={["0"]}
          alwaysOpen
        >
          {answers.map((answer, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header as="h3">
                  <p>{questions[index]}</p>
                </Accordion.Header>
                <Accordion.Body>
                  <p>{answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </section>
    </div>
  );
}

export default Content;
