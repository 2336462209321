import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade } from "swiper";
import SlideButton from "./Slide-Button";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";

// import Timer2 from "./Timer2.0";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import React, { useState, useEffect } from "react";
import { dateInBetween, SPEEDSIZE_PREFIX } from "../App";
import { Arrowrightw } from "../icons";

const CTA_URL = "https://m.caba.codere.bet.ar/deportes/#/mso/RegistroARPage";

function MySwiper({ device }) {
  const location = window.location.host;

  const [imgs, setImgs] = useState([]);

  const [swiper, setSwiper] = useState(null);

  const [liveBanners, setLiveBanners] = useState([]);
  const [currentTycType, setCurrentTycType] = useState(null);
  const [currentBannerName, setCurrentBannerName] = useState("");
  const [showTycModal, setShowTycModal] = useState(false);
  const [ctaText, setCtaText] = useState("");
  const [ctaLink, setCtaLink] = useState(CTA_URL);
  const [region, setCurrentregion] = useState("All");

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_SERVER || "http://localhost:5001"
        }/prd/argentina`
      )
      .then((res) => {
        let x = res.data[0].slide_list.filter(BannerFilter);
        setImgs([...x]);

        setLiveBanners(
          x.reduce((acc, obj) => {
            const tempBanner = {
              ...obj,
              image: obj.image.replace("xxx", device ? "mobile" : "desktop"),
            };
            acc.push(tempBanner);
            return acc;
          }, [])
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let tempList = imgs.reduce((acc, obj) => {
      const tempBanner = {
        ...obj,
        image: obj.image.replace("xxx", device ? "mobile" : "desktop"),
      };
      acc.push(tempBanner);
      return acc;
    }, []);

    setLiveBanners([...tempList]);
  }, [device]);

  useEffect(() => {
    try {
      if (showTycModal) swiper.autoplay.pause();
    } catch (error) {
      console.log(error);
    }
  }, [showTycModal]);

  const indexHandler = (swiper) => {
    setSwiper(swiper);
    try {
      setCtaText(liveBanners[swiper.realIndex].ctaText);
      setCtaLink(liveBanners[swiper.realIndex].ctaURL || CTA_URL);
      setCurrentTycType(liveBanners[swiper.realIndex].tycType || null);
      setCurrentBannerName(liveBanners[swiper.realIndex].tycTitle || "");
      setCurrentregion(liveBanners[swiper.realIndex].region || "All");
    } catch (error) {}
  };

  function BannerFilter(banner) {
    if (!banner.scheduled) return banner;
    else {
      if (dateInBetween(banner)) return banner;
    }
  }

  const modalGenerator = () => {
    return (
      <Modal
        centered
        show={showTycModal}
        onHide={() => setShowTycModal(false)}
        contentClassName="terms-tyc"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="test">
              <h5>Términos y condiciones</h5>
              <h5>{currentBannerName}</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentTycType && tycGenerator(currentTycType)}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowTycModal(false)} className="accept-btn">
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const tycGenerator = (type) => {
    return (
      <>
        <iframe
          width="100%"
          height="400px"
          src={
            location.includes("codere.bet.ar")
              ? `https://www.codere.bet.ar/assets/tyc/arg-tyc-${type}.html` // for prd env
              : `https://blog.codere.bet.ar/terminos-y-condiciones/${type}` // for testing env
          }
        />
      </>
    );
  };

  return (
    <div id="carousel-section">
      {liveBanners.length > 0 && (
        <>
          <Swiper
            onSlideChange={(swiper) => indexHandler(swiper)}
            modules={[Pagination, EffectFade, Autoplay]}
            pagination={{ clickable: true }}
            effect="fade"
            autoplay={{ delay: 3250, disableOnInteraction: false }}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
          >
            {liveBanners.map((item, index) => (
              <SwiperSlide key={item.banner_name}>
                <a href={ctaLink}>
                  <img
                    src={`${SPEEDSIZE_PREFIX}${item.image}`}
                    alt={item.name}
                  />
                </a>

                {/* Timer for live Text */}
                {/* {showTimer && new Date() < new Date(DATE_TO_COUNT) && (
                    <Timer2 />
                  )} */}
              </SwiperSlide>
            ))}
          </Swiper>

          {ctaText && (
            <>
              {!device ? (
                <div id="des-reg">
                  <Button
                    href={ctaLink}
                    className="central-regis gl-effect"
                    rel={"nofollow"}
                  >
                    {ctaText}
                    <Arrowrightw />
                  </Button>
                  {region != "All" && region && (
                    <div className="text-white fs-7">
                      Disponible únicamente dentro de{" "}
                      {region.toLocaleUpperCase()}
                    </div>
                  )}
                  {currentTycType && (
                    <span className="tyc" onClick={() => setShowTycModal(true)}>
                      Términos y condiciones
                    </span>
                  )}
                </div>
              ) : (
                <div className="ReactSwipeButton mobile_regis">
                  <SlideButton regis={ctaLink} ctaText={ctaText} />
                  <div>
                    {region != "All" && region && (
                      <div>
                        Disponible únicamente dentro de{" "}
                        {region.toLocaleUpperCase()}
                      </div>
                    )}
                    {currentTycType && (
                      <span
                        className="tyc"
                        onClick={() => setShowTycModal(true)}
                      >
                        Términos y condiciones
                      </span>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {modalGenerator()}
        </>
      )}
    </div>
  );
}
export default MySwiper;
